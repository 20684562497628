export class PlayerCam2 {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "PlayerCam2";
    this.scene = "FeNC_FF_2PlayerCam";
    this.data = [];
  }

  preview({ options }) {
    this.onAir = false;
    this.data = [];

    let left_team = options.switch
      ? options.game?.away_team
      : options.game?.home_team;
    let right_team = options.switch
      ? options.game?.home_team
      : options.game?.away_team;
    let left_lineup = options.switch
      ? options.game?.lineup?.away
      : options.game?.lineup?.home;
    let right_lineup = options.switch
      ? options.game?.lineup?.home
      : options.game?.lineup?.away;
    this.data.push({
      name: "LeftTeamName",
      value: {
        text: left_team?.name || "",
      },
    });
    this.data.push({
      name: "LeftTeamFLAG",
      value: {
        image: left_team?.code || "",
      },
    });
    this.data.push({
      name: "RightTeamName",
      value: {
        text: right_team?.name || "",
      },
    });

    this.data.push({
      name: "RightTeamFLAG",
      value: {
        image: right_team?.code || "",
      },
    });

    let left_names = "";
    left_lineup?.forEach((player, index) => {
      left_names += player?.squad?.player?.first_name;
      if (index < options.game.lineup.home.length - 1) {
        left_names += " + ";
      }
    });
    this.data.push({
      name: "LeftPlayerNames",
      value: {
        text: left_names,
      },
    });

    let right_names = "";
    right_lineup?.forEach((player, index) => {
      right_names += player?.squad?.player?.first_name;
      if (index < options.game.lineup.away.length - 1) {
        right_names += " + ";
      }
    });
    this.data.push({
      name: "RightPlayerNames",
      value: {
        text: right_names,
      },
    });

    this.sendPreview(this.scene, 500, this.data, "IN");
  }
  animate({ options }) {
    this.onAir = true;

    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }
}
