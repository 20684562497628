import moment from "moment";

export class NextUp {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "NextUp";
    this.scene = "FeNC_FF_NextUp";
    this.data = [];
  }

  preview({ options }) {
    this.onAir = false;
    this.data = [];
    this.options = options;
    let left_team =
      options.teamSides === 0
        ? options.game?.home_team
        : options.game?.away_team;

    let right_team =
      options.teamSides === 1
        ? options.game?.home_team
        : options.game?.away_team;

    let left_score =
      options.teamSides === 0
        ? options.game?.home_scorers?.filter((s) => s.game === options.game_num)
            ?.length || 0
        : options.game?.away_scorers?.filter((s) => s.game === options.game_num)
            ?.length || 0;

    let right_score =
      options.teamSides === 1
        ? options.game?.home_scorers?.filter((s) => s.game === options.game_num)
            ?.length || 0
        : options.game?.away_scorers?.filter((s) => s.game === options.game_num)
            ?.length || 0;

    let left_lineup =
      options.teamSides === 0
        ? options.game.lineup?.home
        : options.game.lineup?.away;

    let right_lineup =
      options.teamSides === 1
        ? options.game.lineup?.home
        : options.game.lineup?.away;

    this.data.push({
      name: "FinalLoopGRP",
      value: {
        visible: options?.game?.fifa_position === 15,
      },
    });
    this.data.push({
      name: "SemiLoopGRP",
      value: {
        visible: options?.game?.fifa_position !== 15,
      },
    });
    this.data.push({
      name: "LH_TeamName",
      value: {
        text: left_team?.name || "",
      },
    });
    this.data.push({
      name: "LH_Flag",
      value: {
        image: left_team?.code || "",
      },
    });

    this.data.push({
      name: "RH_TeamName",
      value: {
        text: right_team?.name || "",
      },
    });
    this.data.push({
      name: "RH_Flag",
      value: {
        image: right_team?.code || "",
      },
    });

    this.data.push({
      name: "Left_Headshot_1",
      value: {
        image: left_lineup?.[0]?.squad?.player?.last_name_upper,
      },
    });

    this.data.push({
      name: "Left_Headshot_2",
      value: {
        image: left_lineup?.[1]?.squad?.player?.last_name_upper,
      },
    });

    this.data.push({
      name: "Left_Headshot_3",
      value: {
        image: left_lineup?.[2]?.squad?.player?.last_name_upper,
      },
    });

    this.data.push({
      name: "Right_Headshot_1",
      value: {
        image: right_lineup?.[0]?.squad?.player?.last_name_upper,
      },
    });

    this.data.push({
      name: "Right_Headshot_2",
      value: {
        image: right_lineup?.[1]?.squad?.player?.last_name_upper,
      },
    });

    this.data.push({
      name: "Right_Headshot_3",
      value: {
        image: right_lineup?.[2]?.squad?.player?.last_name_upper,
      },
    });
    let left_names = "";
    left_lineup?.forEach((player, index) => {
      left_names += player?.squad?.player?.first_name;
      if (index < left_lineup.length - 1) {
        left_names += " + ";
      }
    });
    this.data.push({
      name: "Left_PlayerNames",
      value: {
        text: left_names,
      },
    });

    let right_names = "";
    right_lineup?.forEach((player, index) => {
      right_names += player?.squad?.player?.first_name;
      if (index < right_lineup.length - 1) {
        right_names += " + ";
      }
    });
    this.data.push({
      name: "Right_PlayerNames",
      value: {
        text: right_names,
      },
    });
    let ko = moment(options?.game?.time, "HH:mm");
    let now = moment();

    let duration = moment.duration(ko.diff(now));
    this.data.push({
      name: "Clock",
      value: {
        action: "start",
        time: duration._milliseconds / 1000,
      },
    });
    this.data.push({
      name: "ClockGRP",
      value: {
        visible: options.clock,
      },
    });

    this.sendPreview(this.scene, 500, this.data, "IN");
  }
  animate({ options }) {
    this.onAir = true;
    debugger;
    let ko = moment(this.options?.game?.time, "HH:mm");
    let now = moment();

    let duration = moment.duration(ko.diff(now));
    this.data.push({
      name: "Clock",
      value: {
        action: "start",
        time: duration._milliseconds / 1000,
      },
    });
    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }
}
