import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import moment from "moment";
import { UIContext, VIEW_CONSTS } from "../../contexts/UIContext";
import { DataContext } from "../../contexts/DataContext";
import { FiPlay } from "react-icons/fi";
import { AiOutlinePause } from "react-icons/ai";
import { Button } from "../../Theme/Hyper";
import { APIContext } from "../../contexts/APIContext";
import { useHistory, useLocation } from "react-router";

const Main = styled.div`
  display: flex;
  font-weight: 100;
  box-shadow: 0px 5px 3px 0 rgb(0 0 0 / 12%);
  width: 100%;
  align-items: center;
  flex-direction: column;
  margin-top: 0.5em;
  padding-bottom: 0.5em;
  z-index: 0;
  border-bottom: 1px solid #3d485d;
`;
const ClockSection = styled.div`
  display: flex;
  min-width: 70px;
  height: 90px;
  justify-content: ${(props) => (props.mins ? "flex-end" : "flex-start")};
`;
const ClockSplit = styled.div`
  height: 90px;
`;
const Half = styled.div`
  display: flex;
  font-size: 1.5em;
`;

const ClockContainer = styled.div`
  display: flex;
  font-size: 4em;
  align-items: center;
  width: 100%;
`;
const Buttons = styled.div`
  display: flex;
  width: 100%;
  padding: 0.5em;
  box-sizing: border-box;
`;
const ClockButton = styled.div`
  font-size: 2em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  align-items: center;
  display: flex;
  background-color: ${(props) => (props.green ? "#0adb93" : "#db0a41")};
  color: ${(props) => (props.green ? "#1d1d1d" : "")};
  /* width: 100%; */
  justify-content: center;
  margin: 0.2em;
  width: 100%;
`;
const AddedTime = styled.div`
  display: flex;
  width: 100%;
  padding: 0.5em;
  box-sizing: border-box;
  flex-wrap: wrap;
  justify-content: center;
`;
const AddedTimeButton = styled.div`
  font-size: 1em;
  padding: 0.5em;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  align-items: center;
  display: flex;
  background-color: ${(props) => (props.selected ? "#db0a41" : "#181d25")};

  /* width: 100%; */
  justify-content: center;
  margin: 0.2em;
  width: 25%;
  box-sizing: border-box;
  :hover {
    background-color: #db0a41;
  }
`;
const PauseResumeButton = styled(Button)`
  font-size: 0.3em;
  padding: 0.5em;
  font-weight: 100;
  margin-left: 0.5em;
  margin-right: 0.5em;
`;
const ClockDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export default function Clock({
  game,
  teams,
  previewGraphic,
  animateGraphic,
  animateOffGraphic,
  updateGraphic,
}) {
  const history = useHistory();
  const location = useLocation();
  const { setView, view } = useContext(UIContext);
  const { update, sky, kits } = useContext(DataContext);
  const { updateGame } = useContext(APIContext);
  const [clock, setClock] = useState();
  const [injuryTime, setInjuryTime] = useState(10);
  const [minutes, setMinutes] = useState();
  useEffect(() => {
    if (game) {
      let period = [...(game?.live_data?.periods || [])].sort(
        (a, b) => b.number - a.number
      )[0];

      if (period) {
        updateClock(period.kick_off, period);
      }
      let interval = setInterval(() => {
        if (period) {
          updateClock(period.kick_off, period);
        }
      }, 500);
      return () => {
        clearInterval(interval);
      };
    } else {
      updateClock(0);
    }
  }, [game]);

  function updateClock(koTime, period) {
    let clockTime = "00:00";

    if (koTime) {
      let pauseTime = 0;

      period.pause &&
        period.pause.forEach((pause) => {
          if (pause.end) {
            pauseTime += pause.end - pause.start;
          }
        });
      let latestPause = period.pause && period.pause[period.pause.length - 1];
      if (latestPause && latestPause.end) {
        latestPause = null;
      }
      let time = moment.duration(
        ((latestPause && latestPause.start) || Date.now()) - koTime - pauseTime
      );

      let minutes = time.minutes() + time.hours() * 60;
      if (period.number === 2) {
        minutes += 45;
      } else if (period.number === 3) {
        minutes += 90;
      } else if (period.number === 4) {
        minutes += 105;
      }
      setMinutes(minutes);
      clockTime =
        minutes.toString().padStart(2, "0") +
        ":" +
        time.seconds().toString().padStart(2, "0");
      setClock(clockTime);
    } else if (period.number) {
      let minutes = 0;
      if (period.number === 2) {
        minutes += 45;
      } else if (period.number === 3) {
        minutes += 90;
      } else if (period.number === 4) {
        minutes += 105;
      }
      setMinutes(minutes);
      clockTime = minutes.toString().padStart(2, "0") + ":00";
      setClock(clockTime);
    }
    if (period) {
      updateGraphic("CLOCK", {
        clock: clockTime,
        game: game,
        period: period,
        sky: sky,
        kits: kits,
      });
    }
  }

  function pause() {
    if (!period.pause) {
      period.pause = [];
    }
    update({
      type: "update_clock",
      _id: game._id,
      data: {
        number: period.number,
        kick_off: period.kick_off,
        pause: [...period.pause, { start: moment().valueOf() }],
      },
    });
  }

  function resume() {
    if (!period.pause) {
      period.pause = [];
    }

    if (!period.pause[period.pause.length - 1].end) {
      period.pause[period.pause.length - 1].end = moment().valueOf();
    }
    update({
      type: "update_clock",
      _id: game._id,
      data: {
        number: period.number,
        kick_off: period.kick_off,
        pause: [...period.pause],
      },
    });
  }

  function getHalfText(number) {
    if (number === 1) {
      return "1st Half";
    } else if (number === 2) {
      return "2nd Half";
    } else if (number === 3) {
      return "ET 1";
    } else if (number === 4) {
      return "ET 2";
    }
    return number;
  }

  function setAddedTime(time) {
    let newData = { ...JSON.parse(JSON.stringify(game)) };
    let index = newData.live_data.periods.findIndex(
      (p) => p.number === period.number
    );
    newData.live_data.periods[index].added_time = time;
    updateGame({ id: game._id, live_data: newData.live_data });
    updateGraphic("CLOCK", {
      type: "ADDED_TIME",
      added_time: time,
    });
  }
  let period = [...(game?.live_data?.periods || [])].sort(
    (a, b) => b.number - a.number
  )[0];

  return (
    <Main>
      {game && period && <Half>{getHalfText(period.number)}</Half>}
      {/* {" "}
        {!game.actual_kick_off && (
          <Button
            green
            onClick={() => {
              updateGame({
                uuid: game.uuid,
                data: { actual_kick_off: moment().valueOf() },
              });
            }}
          >
            KICK OFF
          </Button>
        )} */}

      {clock && (
        <ClockContainer>
          <ClockDiv
            onClick={() => {
              if (location.pathname.indexOf("clock") > -1) {
                history.push(location.pathname.replace("/clock", ""));
              } else {
                history.push("/" + game._id + "/clock");
              }
            }}
          >
            <ClockSection mins>{clock.split(":")[0]}</ClockSection>
            <ClockSplit>:</ClockSplit>
            <ClockSection>{clock.split(":")[1]}</ClockSection>
          </ClockDiv>
        </ClockContainer>
      )}
      {period && !period?.kick_off && (
        <Buttons>
          <ClockButton
            onClick={() => {
              let newData = { ...JSON.parse(JSON.stringify(game)) };
              newData.live_data.periods = [
                { kick_off: moment().valueOf(), number: period.number },
              ];
              updateGame({ id: game._id, live_data: newData.live_data });
            }}
          >
            Kick Off
          </ClockButton>
        </Buttons>
      )}
      {period && period?.kick_off && (
        <Column>
          <Buttons>
            <ClockButton
              onClick={() => {
                animateOffGraphic("CLOCK", {});
              }}
            >
              OUT
            </ClockButton>

            <ClockButton
              onClick={() => {
                animateGraphic("CLOCK", {
                  teams: teams,
                  game: game,
                  clock: clock,
                  period: period,
                  sky: sky,
                  kits: kits,
                  added_time: period.added_time,
                });
              }}
              green
            >
              IN
            </ClockButton>
          </Buttons>

          {((period?.number === 1 && minutes >= 45) ||
            (period?.number === 2 && minutes >= 90) ||
            (period?.number === 3 && minutes >= 104) ||
            (period?.number === 4 && minutes >= 119)) && (
            <Buttons>
              <ClockButton
                onClick={() => {
                  animateOffGraphic("CLOCK", {});
                  setTimeout(() => {
                    let newData = { ...JSON.parse(JSON.stringify(game)) };
                    newData.live_data.periods.push({
                      kick_off: null,
                      number: period.number + 1,
                    });
                    updateGame({ id: game._id, live_data: newData.live_data });

                    // update({
                    //   type: "update_clock",
                    //   _id: game._id,
                    //   data: {
                    //     number: period.number + 1,
                    //     kick_off: null,
                    //   },
                    // });
                  }, 2000);
                }}
              >
                End Half
              </ClockButton>
            </Buttons>
          )}
        </Column>
      )}

      {((period?.number === 1 && minutes >= 44) ||
        (period?.number === 2 && minutes >= 89) ||
        (period?.number === 3 && minutes >= 104) ||
        (period?.number === 4 && minutes >= 119)) && (
        <AddedTime>
          <AddedTimeButton
            selected={period.added_time === 1}
            onClick={() => setAddedTime(1)}
          >
            +1
          </AddedTimeButton>
          <AddedTimeButton
            selected={period.added_time === 2}
            onClick={() => setAddedTime(2)}
          >
            +2
          </AddedTimeButton>
          <AddedTimeButton
            selected={period.added_time === 3}
            onClick={() => setAddedTime(3)}
          >
            +3
          </AddedTimeButton>
          <AddedTimeButton
            selected={period.added_time === 4}
            onClick={() => setAddedTime(4)}
          >
            +4
          </AddedTimeButton>
          <AddedTimeButton
            selected={period.added_time === 5}
            onClick={() => setAddedTime(5)}
          >
            +5
          </AddedTimeButton>
          <AddedTimeButton
            selected={period.added_time === 6}
            onClick={() => setAddedTime(6)}
          >
            +6
          </AddedTimeButton>
          <AddedTimeButton
            selected={period.added_time === 7}
            onClick={() => setAddedTime(7)}
          >
            +7
          </AddedTimeButton>
          <AddedTimeButton
            selected={period.added_time === 8}
            onClick={() => setAddedTime(8)}
          >
            +8
          </AddedTimeButton>
          <AddedTimeButton
            selected={period.added_time === 9}
            onClick={() => setAddedTime(9)}
          >
            +9
          </AddedTimeButton>
          <AddedTimeButton
            onClick={() => {
              setInjuryTime(injuryTime - 1);
            }}
          >
            -
          </AddedTimeButton>
          <AddedTimeButton
            selected={period.added_time === injuryTime}
            onClick={() => setAddedTime(injuryTime)}
          >
            +{injuryTime}
          </AddedTimeButton>
          <AddedTimeButton
            onClick={() => {
              setInjuryTime(injuryTime + 1);
            }}
          >
            +
          </AddedTimeButton>
          <AddedTimeButton
            onClick={() => {
              setAddedTime("");
            }}
          >
            Remove
          </AddedTimeButton>
        </AddedTime>
      )}
    </Main>
  );
}
