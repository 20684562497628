export class Bracket {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "Bracket";
    this.scene_left = "FeNC_FF_DrawLeft";
    this.scene_right = "FeNC_FF_DrawRight";
    this.scene_final = "FeNC_FF_DrawFinal";
    this.data = [];
  }

  preview({ options }) {
    this.onAir = false;
    this.data = [];
    this.scene = options.left ? this.scene_left : this.scene_right;

    if (options.final) {
      this.scene = this.scene_final;
    }
    debugger;
    if (options.final === false) {
      options.games
        ?.filter((g) => {
          if (options.left) {
            return g.fifa_position <= 4;
          } else {
            return g.fifa_position > 4 && g.fifa_position <= 8;
          }
        })
        .forEach((game, index) => {
          let game1_complete =
            game?.fifa_result[0].status !== "upcoming" &&
            (game?.fifa_result[0].result_type === "full-time" ||
              game?.fifa_result[0].result_type === "penalties" ||
              game?.fifa_result[0].result_type === "extra-time");

          let game2_complete =
            game?.fifa_result[1].status !== "upcoming" &&
            (game?.fifa_result[1].result_type === "full-time" ||
              game?.fifa_result[1].result_type === "penalties" ||
              game?.fifa_result[1].result_type === "extra-time");

          let row = index + 1;
          this.data.push({
            name: "AET_B_" + row,
            value: {
              text:
                game?.fifa_result[1]?.result_type === "penalties"
                  ? "PSO"
                  : game?.fifa_result[1]?.result_type === "extra-time"
                  ? "AET"
                  : "",
            },
          });

          this.data.push({
            name: "B" + row + "_Name_1",
            value: {
              text: game?.home_team?.name?.toUpperCase() || "TBD",
            },
          });
          this.data.push({
            name: "B" + row + "_Flag_1",
            value: {
              image: game?.home_team?.code || "flag_tbd_3.png",
            },
          });
          this.data.push({
            name: "B" + row + "_Name_2",
            value: {
              text: game?.away_team?.name?.toUpperCase() || "TBD",
            },
          });
          this.data.push({
            name: "B" + row + "_Flag_2",
            value: {
              image: game?.away_team?.code || "flag_tbd_3.png",
            },
          });

          this.data.push({
            name: "B" + row + "_TopScore_1",
            value: {
              text: game1_complete
                ? game?.home_scorers?.filter((g) => g.game === 1)?.length
                : "-",
            },
          });
          this.data.push({
            name: "B" + row + "_TopScore_2",
            value: {
              text: game2_complete
                ? game?.home_scorers?.filter((g) => g.game === 2)?.length
                : "-",
            },
          });
          this.data.push({
            name: "B" + row + "_BotScore_1",
            value: {
              text: game1_complete
                ? game?.away_scorers?.filter((g) => g.game === 1)?.length
                : "-",
            },
          });
          this.data.push({
            name: "B" + row + "_BotScore_2",
            value: {
              text: game2_complete
                ? game?.away_scorers?.filter((g) => g.game === 2)?.length
                : "-",
            },
          });
        });
      options.games
        ?.filter((g) => {
          if (options.left) {
            return g.fifa_position > 8 && g.fifa_position <= 10;
          } else {
            return g.fifa_position > 10 && g.fifa_position <= 12;
          }
        })
        .forEach((game, index) => {
          let game1_complete =
            game?.fifa_result[0].status !== "upcoming" &&
            (game?.fifa_result[0].result_type === "full-time" ||
              game?.fifa_result[0].result_type === "penalties" ||
              game?.fifa_result[0].result_type === "extra-time");

          let game2_complete =
            game?.fifa_result[1].status !== "upcoming" &&
            (game?.fifa_result[1].result_type === "full-time" ||
              game?.fifa_result[1].result_type === "penalties" ||
              game?.fifa_result[1].result_type === "extra-time");
          let row = index + 5;
          this.data.push({
            name: "AET_B_" + row,
            value: {
              text:
                game?.fifa_result[1]?.result_type === "penalties"
                  ? "PSO"
                  : game?.fifa_result[1]?.result_type === "extra-time"
                  ? "AET"
                  : "",
            },
          });
          this.data.push({
            name: "B" + row + "_Name_1",
            value: {
              text: game?.home_team?.name?.toUpperCase() || "TBD",
            },
          });
          this.data.push({
            name: "B" + row + "_Flag_1",
            value: {
              image: game?.home_team?.code || "flag_tbd_3.png",
            },
          });
          this.data.push({
            name: "B" + row + "_Name_2",
            value: {
              text: game?.away_team?.name?.toUpperCase() || "TBD",
            },
          });
          this.data.push({
            name: "B" + row + "_Flag_2",
            value: {
              image: game?.away_team?.code || "flag_tbd_3.png",
            },
          });
          this.data.push({
            name: "B" + row + "_TopScore_1",
            value: {
              text: game1_complete
                ? game?.home_scorers?.filter((g) => g.game === 1)?.length
                : "-",
            },
          });
          this.data.push({
            name: "B" + row + "_TopScore_2",
            value: {
              text: game2_complete
                ? game?.home_scorers?.filter((g) => g.game === 2)?.length
                : "-",
            },
          });
          this.data.push({
            name: "B" + row + "_BotScore_1",
            value: {
              text: game1_complete
                ? game?.away_scorers?.filter((g) => g.game === 1)?.length
                : "-",
            },
          });
          this.data.push({
            name: "B" + row + "_BotScore_2",
            value: {
              text: game2_complete
                ? game?.away_scorers?.filter((g) => g.game === 2)?.length
                : "-",
            },
          });
        });

      options.games
        ?.filter((g) => {
          if (options.left) {
            return g.fifa_position === 13;
          } else {
            return g.fifa_position === 14;
          }
        })
        .forEach((game, index) => {
          let row = index + 7;
          let game1_complete =
            game?.fifa_result[0].status !== "upcoming" &&
            (game?.fifa_result[0].result_type === "full-time" ||
              game?.fifa_result[0].result_type === "penalties" ||
              game?.fifa_result[0].result_type === "extra-time");

          let game2_complete =
            game?.fifa_result[1].status !== "upcoming" &&
            (game?.fifa_result[1].result_type === "full-time" ||
              game?.fifa_result[1].result_type === "penalties" ||
              game?.fifa_result[1].result_type === "extra-time");
          this.data.push({
            name: "AET_B_" + row,
            value: {
              text:
                game?.fifa_result[1]?.result_type === "penalties"
                  ? "PSO"
                  : game?.fifa_result[1]?.result_type === "extra-time"
                  ? "AET"
                  : "",
            },
          });
          this.data.push({
            name: "B" + row + "_Name_1",
            value: {
              text: game?.home_team?.name?.toUpperCase() || "TBD",
            },
          });
          this.data.push({
            name: "B" + row + "_Flag_1",
            value: {
              image: game?.home_team?.code || "flag_tbd_3.png",
            },
          });
          this.data.push({
            name: "B" + row + "_Name_2",
            value: {
              text: game?.away_team?.name?.toUpperCase() || "TBD",
            },
          });
          this.data.push({
            name: "B" + row + "_Flag_2",
            value: {
              image: game?.away_team?.code || "flag_tbd_3.png",
            },
          });
          this.data.push({
            name: "B" + row + "_TopScore_1",
            value: {
              text: game1_complete
                ? game?.home_scorers?.filter((g) => g.game === 1)?.length
                : "-",
            },
          });
          this.data.push({
            name: "B" + row + "_TopScore_2",
            value: {
              text: game2_complete
                ? game?.home_scorers?.filter((g) => g.game === 2)?.length
                : "-",
            },
          });
          this.data.push({
            name: "B" + row + "_BotScore_1",
            value: {
              text: game1_complete
                ? game?.away_scorers?.filter((g) => g.game === 1)?.length
                : "-",
            },
          });
          this.data.push({
            name: "B" + row + "_BotScore_2",
            value: {
              text: game2_complete
                ? game?.away_scorers?.filter((g) => g.game === 2)?.length
                : "-",
            },
          });
        });
    } else if (options.final) {
      options.games
        ?.filter((g) => {
          return (
            g.fifa_position === 13 ||
            g.fifa_position === 14 ||
            g.fifa_position === 15
          );
        })
        .forEach((game, index) => {
          let game1_complete =
            game?.fifa_result[0].status !== "upcoming" &&
            (game?.fifa_result[0].result_type === "full-time" ||
              game?.fifa_result[0].result_type === "penalties" ||
              game?.fifa_result[0].result_type === "extra-time");

          let game2_complete =
            game?.fifa_result[1].status !== "upcoming" &&
            (game?.fifa_result[1].result_type === "full-time" ||
              game?.fifa_result[1].result_type === "penalties" ||
              game?.fifa_result[1].result_type === "extra-time");
          let row = index + 5;
          this.data.push({
            name: row === 5 ? "AET_SF_1" : row === 6 ? "AET_SF_2" : "AET_F",
            value: {
              text:
                game?.fifa_result[1]?.result_type === "penalties"
                  ? "PSO"
                  : game?.fifa_result[1]?.result_type === "extra-time"
                  ? "AET"
                  : "",
            },
          });
          this.data.push({
            name: "B" + row + "_Name_1",
            value: {
              text: game?.home_team?.name?.toUpperCase() || "TBD",
            },
          });
          this.data.push({
            name: "B" + row + "_Flag_1",
            value: {
              image: game?.home_team?.code || "flag_tbd_3.png",
            },
          });
          this.data.push({
            name: "B" + row + "_Name_2",
            value: {
              text: game?.away_team?.name?.toUpperCase() || "TBD",
            },
          });
          this.data.push({
            name: "B" + row + "_Flag_2",
            value: {
              image: game?.away_team?.code || "flag_tbd_3.png",
            },
          });

          this.data.push({
            name: "B" + row + "_TopScore_1",
            value: {
              text: game1_complete
                ? game?.home_scorers?.filter((g) => g.game === 1)?.length
                : "-",
            },
          });
          this.data.push({
            name: "B" + row + "_TopScore_2",
            value: {
              text: game2_complete
                ? game?.home_scorers?.filter((g) => g.game === 2)?.length
                : "-",
            },
          });
          this.data.push({
            name: "B" + row + "_BotScore_1",
            value: {
              text: game1_complete
                ? game?.away_scorers?.filter((g) => g.game === 1)?.length
                : "-",
            },
          });
          this.data.push({
            name: "B" + row + "_BotScore_2",
            value: {
              text: game2_complete
                ? game?.away_scorers?.filter((g) => g.game === 2)?.length
                : "-",
            },
          });
        });
    }
    this.sendPreview(this.scene, 500, this.data, "IN");
  }
  animate({ options }) {
    this.onAir = true;

    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }
}
