export class Substitution {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "Substitution";
    this.scene = "FeNC_FF_SUBSTITUTE";
    this.data = [];
  }

  preview({ options }) {
    this.onAir = false;
    this.data = [];
    debugger;
    this.data.push({
      name: "LH_PlayerName",
      value: {
        text:
          options?.players[0]?.squad?.player?.first_name?.toUpperCase() || "",
      },
    });
    this.data.push({
      name: "Left_Headshot_1",
      value: {
        image: options?.players[0]?.squad?.player?.last_name_upper || "",
      },
    });
    this.data.push({
      name: "RH_PlayerName",
      value: {
        text:
          options?.players[1]?.squad?.player?.first_name?.toUpperCase() || "",
      },
    });
    this.data.push({
      name: "Right_Headshot_1",
      value: {
        image: options?.players[1]?.squad?.player?.last_name_upper || "",
      },
    });
    this.sendPreview(this.scene, 500, this.data, "IN");
  }
  animate({ options }) {
    this.onAir = true;

    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }
}
