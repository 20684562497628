import React, { useCallback, useContext, useEffect, useState } from "react";
import Game from "../Game/Game";
import RightSideBar from "../RightSideBar/RightSideBar";
import styled from "styled-components";
import { GraphicsContext } from "../../contexts/GraphicsContext";
import TeamNames from "../TopBar/TeamNames";
import Goal from "../TopBar/Goal";
import { UIContext } from "../../contexts/UIContext";
import ClockEdit from "../ClockEdit/ClockEdit";
import { Route, Switch, useParams } from "react-router";
import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import Goals from "../Goals/Goals";
import Penalties from "../Penalties/Penalties";
import { ControlContext } from "../../contexts/ControlContext";
import { APIContext } from "../../contexts/APIContext";
import { Button } from "../../Theme/Hyper";
import TeamLogger from "../TopBar/TeamLogger";
import { HeartRateContext } from "../../contexts/HeartRateContext";
const LINEUP_FIELDS = gql`
  fragment LINEUP_FIELDS on LineupObject {
    home {
      squad {
        _id
        shirt_number
        opta_shirt_number
        player {
          _id
          first_name
          last_name
          first_name_upper
          last_name_upper
          nationality {
            _id
            opta_code
            opta_name
          }
        }
      }
      order
      yellow_card
      dbl_yellow_card
      red_card
      sub_order
      subbed
      captain
    }
    away {
      squad {
        _id
        shirt_number
        opta_shirt_number
        player {
          _id
          first_name
          last_name
          first_name_upper
          last_name_upper
          nationality {
            _id
            opta_code
            opta_name
          }
        }
      }
      order
      yellow_card
      dbl_yellow_card
      red_card
      sub_order
      subbed
      captain
    }
  }
`;
const GAME_BY_ID = gql`
  ${LINEUP_FIELDS}
  query GameByID($id: String) {
    gameById(id: $id) {
      _id
      time
      description
      date
      home_team_kit
      away_team_kit
      stadium {
        opta_name
      }
      competition {
        name
        badge
      }
      home_scorers
      away_scorers
      home_penalties
      away_penalties
      lineup {
        ...LINEUP_FIELDS
      }
      home_team {
        _id
        name
        short_name
        code
        variables
      }
      away_team {
        _id
        name
        short_name
        code
        variables
      }
      live_data
      home_formation
      away_formation
      home_stats
      away_stats
      season {
        _id
      }
      commentators {
        first_name
        last_name
      }
    }
  }
`;

const GAME_SUBSCRIPTION = gql`
  ${LINEUP_FIELDS}
  subscription GameUpdated($id: String!) {
    gameUpdated(_id: $id) {
      _id
      home_team_kit
      away_team_kit
      lineup {
        ...LINEUP_FIELDS
      }
      live_data
      home_scorers
      away_scorers
      home_penalties
      away_penalties
      home_formation
      away_formation
      home_stats
      away_stats
      season {
        _id
      }
      commentators {
        first_name
        last_name
      }
    }
  }
`;

const Main = styled.div`
  display: flex;
  width: 100%;
`;
const Content = styled.div`
  display: flex;
  margin-left: 1em;
  margin-right: 1em;
  margin-top: 1em;
  box-sizing: border-box;
  flex: 1;
  flex-direction: column;
`;
const RightBar = styled.div`
  display: flex;
  width: 300px;
  box-shadow: -3px 1px 3px 0 rgb(0 0 0 / 42%);
`;
const LeftBar = styled.div`
  display: flex;
  width: 44px;
  box-shadow: 3px -1px 3px 0 rgb(0 0 0 / 42%);
  background-color: #202731;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
  align-items: center;
`;

const Row = styled.div`
  display: flex;
`;

const Half = styled.div`
  width: 45%;
  display: flex;
  justify-content: center;
`;
const TeamButton = styled(Button)`
  background-color: ${(props) => (props.selected ? "#14ec8e" : "")};
  color: ${(props) => (props.selected ? "#000" : "")};
`;
export default function BigScreenPlayout({
  setSelectedPlayer,
  selectedPlayer,
}) {
  const { teamSides, setTeamSides } = useContext(UIContext);
  let { gameID } = useParams();
  const [game, setGame] = useState();
  const [gameNum, setGameNum] = useState(1);
  const [homeManager, setHomeManager] = useState();
  const [awayManager, setAwayManager] = useState();
  const [getGame, { data, subscribeToMore }] = useLazyQuery(GAME_BY_ID);
  const { animateGraphic, updateGraphic, previewGraphic } =
    useContext(GraphicsContext);
  const { data: hrData } = useContext(HeartRateContext);
  const { clear } = useContext(ControlContext);

  useEffect(() => {
    if (gameID) {
      getGame({ variables: { id: gameID } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameID]);

  useEffect(() => {
    let unsubscribe;
    if (subscribeToMore) {
      console.log("Subscribed");
      unsubscribe = subscribeToMore({
        document: GAME_SUBSCRIPTION,
        variables: { id: gameID },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;

          const updatedGame = subscriptionData.data.gameUpdated;
          let newGame = {
            gameById: { ...prev.gameById, ...updatedGame },
          };

          return newGame;
        },
      });
    }
    return () => {
      console.log("Unsubscribed");
      unsubscribe && unsubscribe();
    };
  }, [subscribeToMore, gameID]);

  useEffect(() => {
    if (data) {
      setGame(data.gameById);
      update(data.gameById);
    }
  }, [data]);

  useEffect(() => {
    if (hrData && game) {
      updateGraphic("FFHeartRate", {
        rates: hrData,
        game: game,
        game_num: gameNum,
        engine: "41bb1c18-55c8-43ab-a9d3-86b9d0824cc9",
        teamSides,
      });
    }
  }, [hrData, gameNum, game, teamSides]);

  const update = useCallback(
    (data) => {
      updateGraphic("MatchStats", {
        game: data,
        game_num: gameNum,
        half: 0,
        animate: true,
        engine: "41bb1c18-55c8-43ab-a9d3-86b9d0824cc9",
        teamSides,
      });
    },
    [updateGraphic, gameNum, teamSides]
  );

  if (!game) return <div>Loading</div>;
  return (
    game && (
      <Main>
        <Content>
          <TeamLogger
            setSelectedPlayer={setSelectedPlayer}
            homeTeam={game.home_team}
            awayTeam={game.away_team}
            game={game}
          />
          <Row style={{ justifyContent: "center", marginBottom: "40px" }}>
            <TeamButton
              style={{ marginLeft: "5px" }}
              selected={teamSides === 0}
              onClick={() => {
                setTeamSides(0);
              }}
            >
              {game?.home_team?.name} - {game?.away_team?.name}
            </TeamButton>
            <TeamButton
              style={{ marginLeft: "5px" }}
              selected={teamSides === 1}
              onClick={() => {
                setTeamSides(1);
              }}
            >
              {game?.away_team?.name} - {game?.home_team?.name}
            </TeamButton>
          </Row>
          <Row>
            <Half>
              <Button
                style={{ marginRight: "10px" }}
                onClick={() => {
                  setGameNum(1);
                  animateGraphic("FFHeartRate", {
                    game,
                    game_num: 1,
                    engine: "41bb1c18-55c8-43ab-a9d3-86b9d0824cc9",
                    teamSides,
                  });
                  // update();
                }}
              >
                Game 1 Heart Rate
              </Button>
              <Button
                style={{ marginRight: "10px" }}
                onClick={() => {
                  setGameNum(1);
                  previewGraphic("MatchStats", {
                    game,
                    game_num: 1,
                    half: 0,
                    animate: true,
                    engine: "41bb1c18-55c8-43ab-a9d3-86b9d0824cc9",
                    teamSides,
                  });
                }}
              >
                Game 1 Stats
              </Button>
            </Half>
            <Button
              onClick={() => {
                clear("41bb1c18-55c8-43ab-a9d3-86b9d0824cc9");
              }}
            >
              CLEAR ALL GFX <br />
            </Button>
            <Half>
              <Button
                style={{ marginRight: "10px" }}
                onClick={() => {
                  setGameNum(2);
                  animateGraphic("FFHeartRate", {
                    game,
                    game_num: 2,
                    engine: "41bb1c18-55c8-43ab-a9d3-86b9d0824cc9",
                    teamSides,
                  });
                  // update();
                }}
              >
                Game 2 Heart Rate
              </Button>
              <Button
                style={{ marginRight: "10px" }}
                onClick={() => {
                  setGameNum(2);
                  previewGraphic("MatchStats", {
                    game,
                    game_num: 2,
                    half: 0,
                    animate: true,
                    engine: "41bb1c18-55c8-43ab-a9d3-86b9d0824cc9",
                    teamSides,
                  });
                }}
              >
                Game 2 Stats
              </Button>
            </Half>
          </Row>
        </Content>
      </Main>
    )
  );
}
