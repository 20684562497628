export class PlayerName {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "PlayerName";
    this.scene_left = "FeNC_L3_NameSuper";
    this.scene_right = "FeNC_L3_NameSuper_RIGHT";
    this.data = [];
  }

  preview({ options }) {
    this.onAir = false;
    this.data = [];
    if (options.left) {
      this.scene = this.scene_left;
    } else {
      this.scene = this.scene_right;
    }
    this.data.push({
      name: "TopTEXT",
      value: {
        text: options?.player?.first_name || "",
      },
    });

    this.data.push({
      name: "BottTEXT",
      value: {
        text: options?.team?.name?.toUpperCase() || "",
      },
    });

    this.data.push({
      name: "Flag",
      value: {
        image: options?.team?.code || "",
      },
    });
    this.sendPreview(this.scene, 500, this.data, "IN");
  }
  animate({ options }) {
    this.onAir = true;

    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }
}
