export class Champions {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "Champions";
    this.scene = "FeNC_FF_Champions";
    this.data = [];
  }

  preview({ options }) {
    this.onAir = false;
    this.data = [];
    this.options = options;

    this.data.push({
      name: "HeaderOutline",
      value: {
        text: options?.team?.name?.toUpperCase() || "",
      },
    });
    this.data.push({
      name: "Header",
      value: {
        text: options?.team?.name?.toUpperCase() || "",
      },
    });

    this.data.push({
      name: "Right_Headshot_1",
      value: {
        image: options?.lineup?.[0]?.squad?.player?.last_name_upper,
      },
    });

    this.data.push({
      name: "Right_Headshot_2",
      value: {
        image: options?.lineup?.[1]?.squad?.player?.last_name_upper,
      },
    });

    this.data.push({
      name: "Right_Headshot_3",
      value: {
        image: options?.lineup?.[2]?.squad?.player?.last_name_upper,
      },
    });

    this.sendPreview(this.scene, 500, this.data, "IN", options?.engine);
  }
  animate({ options }) {
    this.onAir = true;

    this.sendAnimate(this.scene, this.data, options?.engine);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff(this.scene, [], options?.engine);
  }
}
