export class GroupResults {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "GroupResults";
    this.scene = "FeNC_FF_GS_DoNotUSE";
    this.data = [];
  }

  preview({ options }) {
    this.onAir = false;
    this.data = [];
    this.data.push({
      name: "GroupTXT",
      value: {
        text: options.group || "",
      },
    });
    this.data.push({
      name: "RoundTXT",
      value: {
        text: options.round || "",
      },
    });
    this.data.push({
      name: "HeaderOutline",
      value: {
        text: options.group || "",
      },
    });
    options.games.map((game, index) => {
      let row = index + 1;
      this.data.push({
        name: "L" + row + "_H_Flag",
        value: {
          image: game?.home_team?.code || "",
        },
      });
      this.data.push({
        name: "L" + row + "_H_Name",
        value: {
          text: game?.home_team?.name?.toUpperCase() || "",
        },
      });
      this.data.push({
        name: "L" + row + "_A_Flag",
        value: {
          image: game?.home_team?.code || "",
        },
      });
      this.data.push({
        name: "L" + row + "_A_Name",
        value: {
          text: game?.away_team?.name?.toUpperCase() || "",
        },
      });
      this.data.push({
        name: "L" + row + "_H_Score",
        value: {
          text: game?.home_scorers?.length || 0,
        },
      });
      this.data.push({
        name: "L" + row + "_A_Score",
        value: {
          text: game?.away_scorers?.length || 0,
        },
      });
    });
    debugger;
    this.sendPreview(this.scene, 500, this.data, "IN");
  }
  animate({ options }) {
    this.onAir = true;

    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }
}
