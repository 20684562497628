import { BGLoop } from "./BGLoop";
import { Bracket } from "./Bracket";
import { Champions } from "./Champions";
import { FFHeartRate } from "./FFHeartRate";
import { FinalMatchUps } from "./FinalMatchUps";
import { Group } from "./Group";
import { GroupResults } from "./GroupResults";
import { MatchScore } from "./MatchScore";
import { MatchStats } from "./MatchStats";
import { NextUp } from "./NextUp";
import { PlayerCam1 } from "./PlayerCam1";
import { PlayerCam2 } from "./PlayerCam2";
import { PlayerHeartRate } from "./PlayerHeartRate";
import { PlayerName } from "./PlayerName";
import { PlayerStats } from "./PlayerStats";
import { QFMatchUps } from "./QFMatchUps";
import { SFMatchUps } from "./SFMatchUps";
import { Substitution } from "./Substitution";
import { TeamStats } from "./TeamStats";

export default function graphics({
  preview,
  animate,
  update,
  animateOff,
  triggerAnimation,
  clearPreview,
}) {
  return [
    new Group({ preview, animate, update, animateOff }),
    new GroupResults({ preview, animate, update, animateOff }),
    new Bracket({ preview, animate, update, animateOff }),
    new QFMatchUps({ preview, animate, update, animateOff }),
    new SFMatchUps({ preview, animate, update, animateOff }),
    new FinalMatchUps({ preview, animate, update, animateOff }),
    new PlayerName({ preview, animate, update, animateOff }),
    new MatchScore({ preview, animate, update, animateOff }),
    new MatchStats({ preview, animate, update, animateOff }),
    new NextUp({ preview, animate, update, animateOff }),
    new TeamStats({ preview, animate, update, animateOff }),
    new PlayerStats({ preview, animate, update, animateOff }),
    new PlayerHeartRate({ preview, animate, update, animateOff }),
    new Substitution({ preview, animate, update, animateOff }),
    new BGLoop({ preview, animate, update, animateOff }),
    new FFHeartRate({ preview, animate, update, animateOff }),
    new PlayerCam1({ preview, animate, update, animateOff }),
    new PlayerCam2({ preview, animate, update, animateOff }),
    new Champions({ preview, animate, update, animateOff }),
  ];
}
