import React, { useContext } from "react";
import styled from "styled-components";
import { UIContext, VIEW_CONSTS } from "../../contexts/UIContext";
import { DataContext } from "../../contexts/DataContext";
import { APIContext } from "../../contexts/APIContext";
import { Button } from "../../Theme/Hyper";
import { GraphicsContext } from "../../contexts/GraphicsContext";

const TeamNamePanel = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 1em;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  background-color: #181d25;
  height: 7vh;
`;
const Team = styled.div`
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  display: flex;
  width: 40%;
  align-items: center;
  height: 54px;
`;
const Middle = styled.div`
  display: flex;
  width: 20%;
  justify-content: space-evenly;
  align-items: center;
  margin-top: -10px;
`;
const TeamName = styled.div`
  padding-left: ${(props) => (props.home ? "0.5em" : "")};
  margin-left: ${(props) => (props.home ? "auto" : "")};
  display: flex;
  font-size: 1.5em;
  cursor: pointer;
`;
const Score = styled.div`
  display: flex;
  font-size: 4em;
  width: 100%;
  justify-content: center;
  cursor: pointer;
  border-radius: 2px;
  :hover {
    background-color: #db0a41;
  }
`;

export default function TeamLogger({ setGoalScored, game, setSelectedPlayer }) {
  const { setView, selectedTeam, setGoal } = useContext(UIContext);
  const { previewGraphic } = useContext(GraphicsContext);
  let homeBGColour =
    game.home_team?.variables?.[game?.home_team_kit + "_kit_bg"] || "";
  let awayBGColour =
    game.away_team?.variables?.[game?.away_team_kit + "_kit_bg"] || "";

  return (
    <>
      <TeamNamePanel>
        <Team
          style={{
            borderBottom:
              "3px solid " + (homeBGColour ? homeBGColour : "rgba(0,0,0,0)"),
            opacity:
              !selectedTeam || selectedTeam._id === game.home_team._id
                ? "1"
                : ".2",
          }}
        >
          <div>{game.home_formation?.name}</div>
          <TeamName home>{game.home_team?.name}</TeamName>
        </Team>
        <Middle>
          <Score
            onContextMenu={(e) => {
              e.preventDefault();
            }}
          >
            {game?.home_scorers?.filter((g) => g.game === 1)?.length || 0}
          </Score>
          -
          <Score
            right
            onContextMenu={(e) => {
              e.preventDefault();
            }}
          >
            {game?.away_scorers?.filter((g) => g.game === 1)?.length || 0}
          </Score>
          <Score
            onContextMenu={(e) => {
              e.preventDefault();
            }}
          >
            {game?.home_scorers?.filter((g) => g.game === 2)?.length || 0}
          </Score>
          -
          <Score
            right
            onContextMenu={(e) => {
              e.preventDefault();
            }}
          >
            {game?.away_scorers?.filter((g) => g.game === 2)?.length || 0}
          </Score>
        </Middle>
        <Team
          style={{
            borderBottom:
              "3px solid " + (awayBGColour ? awayBGColour : "rgba(0,0,0,0)"),
            opacity:
              !selectedTeam || selectedTeam._id === game.away_team._id
                ? "1"
                : ".2",
          }}
        >
          <TeamName
          // onClick={() => {
          //   if (selectedTeam && selectedTeam._id === game.away_team._id) {
          //     setSelectedTeam();
          //     setView(VIEW_CONSTS.live);
          //   } else {
          //     setSelectedTeam(game.away_team);
          //     if (view === VIEW_CONSTS.live) {
          //       setView(VIEW_CONSTS.formation);
          //     }
          //   }
          // }}
          >
            {game.away_team?.name}
          </TeamName>
          <div style={{ marginLeft: "auto" }}>{game.away_formation?.name}</div>
        </Team>
      </TeamNamePanel>
    </>
  );
}
