import React, { useContext, useState, useEffect } from "react";
import { DataContext } from "../../contexts/DataContext";
import styled from "styled-components";
import Games from "../Games/Games";
import Live from "./Live";
import { useParams, Switch, Route } from "react-router-dom";
import { GraphicsContext } from "../../contexts/GraphicsContext";
import { UIContext, VIEW_CONSTS } from "../../contexts/UIContext";
import LeftSideBar from "../LeftSideBar/LeftSideBar";
import NavBar from "../TopBar/NavBar";
import Logging from "./Logging";
import BigScreenPlayout from "./BigScreenPlayout";

const MainDiv = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
`;
const LeftBar = styled.div`
  display: flex;
  min-width: 44px;
  box-shadow: 3px -1px 3px 0 rgb(0 0 0 / 42%);
  background-color: #202731;
`;
const Content = styled.div`
  display: flex;
  box-sizing: border-box;
  flex: 1;
  width: 100vw;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;
export default function BigScreen() {
  const { view, setView, editPlayer, setEditPlayer } = useContext(UIContext);
  const { teams, fixtures, selectedGame, setSelectedGame, players } =
    useContext(DataContext);
  const { animateGraphic, graphicInPreview, animateOffGraphic, clearPVW } =
    useContext(GraphicsContext);
  const { setSelectedGameID } = useContext(DataContext);

  const [selectedPlayer, setSelectedPlayer] = useState();
  let { gameID } = useParams();

  useEffect(() => {
    if (players && selectedPlayer) {
      let p = players.find((p) => p.uuid === selectedPlayer.player);
      setSelectedPlayer({ ...p, player: p.uuid });
    }
  }, [players]);

  useEffect(() => {
    if (gameID) {
      setSelectedGameID(gameID);
      if (view === VIEW_CONSTS.matchSelect) {
        setView(VIEW_CONSTS.live);
      }
    } else {
      setView(VIEW_CONSTS.matchSelect);
      setSelectedGame(null);
    }
  }, [fixtures, gameID]);

  return (
    <div>
      <Content>
        <Switch>
          <Route path="/BigScreen/:gameID">
            <LeftBar>
              <LeftSideBar view={VIEW_CONSTS.live} />
            </LeftBar>
            <BigScreenPlayout
              setSelectedPlayer={setSelectedPlayer}
              selectedPlayer={selectedPlayer}
              view={view}
              setView={setView}
            />
          </Route>
          <Route path="/">
            <Column>
              {!window.hideHeader && <NavBar />}

              <MainDiv>
                <LeftBar>
                  <LeftSideBar view={VIEW_CONSTS.matchSelect} />
                </LeftBar>
                <Games
                  fixtures={fixtures}
                  teams={teams}
                  onSelectGame={(game) => {
                    setSelectedGame(game);
                  }}
                />
              </MainDiv>
            </Column>
          </Route>
        </Switch>
      </Content>
    </div>
  );
}
