export class TeamStats {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "TeamStats";
    this.scene = "FeNC_FF_TeamStats";
    this.data = [];
  }

  preview({ options }) {
    this.onAir = false;
    this.data = [];
    this.options = options;

    this.data.push({
      name: "HeaderOutline",
      value: {
        text: options?.team?.name?.toUpperCase() || "",
      },
    });
    this.data.push({
      name: "Header",
      value: {
        text: options?.team?.name?.toUpperCase() || "",
      },
    });

    this.data.push({
      name: "Headshot_1",
      value: {
        image: options?.lineup?.[0]?.squad?.player?.last_name_upper,
      },
    });

    this.data.push({
      name: "Headshot_2",
      value: {
        image: options?.lineup?.[1]?.squad?.player?.last_name_upper,
      },
    });

    this.data.push({
      name: "Headshot_3",
      value: {
        image: options?.lineup?.[2]?.squad?.player?.last_name_upper,
      },
    });
    this.data.push({
      name: "StatName_1",
      value: {
        text: "WORLD RANK",
      },
    });
    this.data.push({
      name: "Stat_1",
      value: {
        text: options?.stats?.world_rank
          ? "#" + options?.stats?.world_rank
          : "-",
      },
    });
    this.data.push({
      name: "StatName_2",
      value: {
        text: "AVERAGE GOALS",
      },
    });
    this.data.push({
      name: "Stat_2",
      value: {
        text: options?.stats?.averageOwnGoals || "0",
      },
    });
    this.data.push({
      name: "StatName_3",
      value: {
        text: "AVG PASSING ACCURACY",
      },
    });
    this.data.push({
      name: "Stat_3",
      value: {
        text: (options?.stats?.averagePassingAccuracy || 0) + "%" || "0%",
      },
    });
    this.data.push({
      name: "StatName_4",
      value: {
        text: "AVG SHOTS",
      },
    });
    this.data.push({
      name: "Stat_4",
      value: {
        text: (options?.stats?.averageShots || 0) + "" || "0",
      },
    });
    this.data.push({
      name: "StatName_5",
      value: {
        text: "AVG EXPECTED GOALS",
      },
    });
    this.data.push({
      name: "Stat_5",
      value: {
        text: (options?.stats?.averageExpectedGoals || 0) + "" || "0",
      },
    });

    this.sendPreview(this.scene, 500, this.data, "IN", options?.engine);
  }
  animate({ options }) {
    this.onAir = true;

    this.sendAnimate(this.scene, this.data, options?.engine);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff(this.scene, [], options?.engine);
  }
}
