export class MatchStats {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "MatchStats";
    this.scene = "FeNC_FF_MatchStats";
    this.data = [];
  }

  preview({ options }) {
    this.onAir = false;
    this.buildData({ options });
    this.sendPreview(this.scene, 500, this.data, "IN", options?.engine);
    if (options.animate) {
      this.animate({ options });
    }
  }
  buildData({ options }) {
    this.data = [];

    let left_team =
      options.teamSides === 0
        ? options.game?.home_team
        : options.game?.away_team;

    let right_team =
      options.teamSides === 1
        ? options.game?.home_team
        : options.game?.away_team;

    let left_score =
      options.teamSides === 0
        ? options.game?.home_scorers?.filter((s) => s.game === options.game_num)
            ?.length || 0
        : options.game?.away_scorers?.filter((s) => s.game === options.game_num)
            ?.length || 0;

    let right_score =
      options.teamSides === 1
        ? options.game?.home_scorers?.filter((s) => s.game === options.game_num)
            ?.length || 0
        : options.game?.away_scorers?.filter((s) => s.game === options.game_num)
            ?.length || 0;

    let left_stats =
      options.teamSides === 0
        ? options.game?.home_stats
        : options.game?.away_stats;

    let right_stats =
      options.teamSides === 1
        ? options.game?.home_stats
        : options.game?.away_stats;

    this.data.push({
      name: "HT-FT-TEXT",
      value: {
        text: options?.half === 1 ? "HALF-TIME" : "FULL-TIME",
      },
    });
    this.data.push({
      name: "HT-FT_GRP",
      value: {
        visible: options?.half > 0 ? true : false,
      },
    });
    this.data.push({
      name: "L1_H_Score",
      value: {
        text: left_score,
      },
    });
    this.data.push({
      name: "L1_A_Score",
      value: {
        text: right_score,
      },
    });
    this.data.push({
      name: "L1_H_Name",
      value: {
        text: left_team?.name?.toUpperCase() || "",
      },
    });
    this.data.push({
      name: "L1_A_Name",
      value: {
        text: right_team?.name?.toUpperCase() || "",
      },
    });
    this.data.push({
      name: "L1_H_Flag",
      value: {
        image: left_team?.code || "",
      },
    });
    this.data.push({
      name: "L1_A_Flag",
      value: {
        image: right_team?.code || "",
      },
    });
    this.data.push({
      name: "H_Stat_1",
      value: {
        text: left_stats?.["game_" + options.game_num]?.shots_on_target || 0,
      },
    });
    this.data.push({
      name: "A_Stat_1",
      value: {
        text: right_stats?.["game_" + options.game_num]?.shots_on_target || 0,
      },
    });
    this.data.push({
      name: "H_Stat_2",
      value: {
        text:
          (left_stats?.["game_" + options.game_num]?.shots_on_target || 0) +
          (left_stats?.["game_" + options.game_num]?.shots_off_target || 0),
      },
    });
    this.data.push({
      name: "A_Stat_2",
      value: {
        text:
          (right_stats?.["game_" + options.game_num]?.shots_on_target || 0) +
          (right_stats?.["game_" + options.game_num]?.shots_off_target || 0),
      },
    });
    this.data.push({
      name: "H_Stat_3",
      value: {
        text: left_stats?.["game_" + options.game_num]?.offside || 0,
      },
    });
    this.data.push({
      name: "A_Stat_3",
      value: {
        text: right_stats?.["game_" + options.game_num]?.offside || 0,
      },
    });
    this.data.push({
      name: "H_Stat_4",
      value: {
        text: left_stats?.["game_" + options.game_num]?.saves || 0,
      },
    });
    this.data.push({
      name: "A_Stat_4",
      value: {
        text: right_stats?.["game_" + options.game_num]?.saves || 0,
      },
    });
    this.data.push({
      name: "H_Stat_5",
      value: {
        text: left_stats?.["game_" + options.game_num]?.corners || 0,
      },
    });
    this.data.push({
      name: "A_Stat_5",
      value: {
        text: right_stats?.["game_" + options.game_num]?.corners || 0,
      },
    });
    this.data.push({
      name: "H_Stat_6",
      value: {
        text: left_stats?.["game_" + options.game_num]?.fouls || 0,
      },
    });
    this.data.push({
      name: "A_Stat_6",
      value: {
        text: right_stats?.["game_" + options.game_num]?.fouls || 0,
      },
    });
    this.data.push({
      name: "H_Stat_7",
      value: {
        text:
          (left_stats?.["game_" + options.game_num]?.yellow_cards || 0) +
          "/" +
          (left_stats?.["game_" + options.game_num]?.red_cards || 0),
      },
    });
    this.data.push({
      name: "A_Stat_7",
      value: {
        text:
          (right_stats?.["game_" + options.game_num]?.yellow_cards || 0) +
          "/" +
          (right_stats?.["game_" + options.game_num]?.red_cards || 0),
      },
    });
  }
  animate({ options }) {
    this.onAir = true;

    this.sendAnimate(this.scene, this.data, options?.engine);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff(this.scene, [], options?.engine);
  }
  update({ options }) {
    this.buildData({ options });
    this.sendUpdate(this.scene, this.data, options?.engine);
  }
}
