import React, { useContext, useEffect, useState } from "react";
import Game from "../Game/Game";
import RightSideBar from "../RightSideBar/RightSideBar";
import styled from "styled-components";
import { GraphicsContext } from "../../contexts/GraphicsContext";
import TeamNames from "../TopBar/TeamNames";
import Goal from "../TopBar/Goal";
import { UIContext } from "../../contexts/UIContext";
import ClockEdit from "../ClockEdit/ClockEdit";
import { Route, Switch, useParams } from "react-router";
import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import Goals from "../Goals/Goals";
import Penalties from "../Penalties/Penalties";
import { ControlContext } from "../../contexts/ControlContext";
import { APIContext } from "../../contexts/APIContext";
import { Button } from "../../Theme/Hyper";
import TeamLogger from "../TopBar/TeamLogger";
const LINEUP_FIELDS = gql`
  fragment LINEUP_FIELDS on LineupObject {
    home {
      squad {
        _id
        shirt_number
        opta_shirt_number
        player {
          _id
          first_name
          last_name
          first_name_upper
          last_name_upper
          nationality {
            _id
            opta_code
            opta_name
          }
        }
      }
      order
      yellow_card
      dbl_yellow_card
      red_card
      sub_order
      subbed
      captain
    }
    away {
      squad {
        _id
        shirt_number
        opta_shirt_number
        player {
          _id
          first_name
          last_name
          first_name_upper
          last_name_upper
          nationality {
            _id
            opta_code
            opta_name
          }
        }
      }
      order
      yellow_card
      dbl_yellow_card
      red_card
      sub_order
      subbed
      captain
    }
  }
`;
const GAME_BY_ID = gql`
  ${LINEUP_FIELDS}
  query GameByID($id: String) {
    gameById(id: $id) {
      _id
      time
      description
      date
      home_team_kit
      away_team_kit
      stadium {
        opta_name
      }
      competition {
        name
        badge
      }
      home_scorers
      away_scorers
      home_penalties
      away_penalties
      lineup {
        ...LINEUP_FIELDS
      }
      home_team {
        _id
        name
        short_name
        code
        variables
      }
      away_team {
        _id
        name
        short_name
        code
        variables
      }
      live_data
      home_formation
      away_formation
      home_stats
      away_stats
      season {
        _id
      }
      commentators {
        first_name
        last_name
      }
    }
  }
`;

const GAME_SUBSCRIPTION = gql`
  ${LINEUP_FIELDS}
  subscription GameUpdated($id: String!) {
    gameUpdated(_id: $id) {
      _id
      home_team_kit
      away_team_kit
      lineup {
        ...LINEUP_FIELDS
      }
      live_data
      home_scorers
      away_scorers
      home_penalties
      away_penalties
      home_formation
      away_formation
      home_stats
      away_stats
      season {
        _id
      }
      commentators {
        first_name
        last_name
      }
    }
  }
`;

const Main = styled.div`
  display: flex;
  width: 100%;
`;
const Content = styled.div`
  display: flex;
  margin-left: 1em;
  margin-right: 1em;
  margin-top: 1em;
  box-sizing: border-box;
  flex: 1;
  flex-direction: column;
`;
const RightBar = styled.div`
  display: flex;
  width: 300px;
  box-shadow: -3px 1px 3px 0 rgb(0 0 0 / 42%);
`;
const LeftBar = styled.div`
  display: flex;
  width: 44px;
  box-shadow: 3px -1px 3px 0 rgb(0 0 0 / 42%);
  background-color: #202731;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
  align-items: center;
`;
export default function Logging({ setSelectedPlayer, selectedPlayer }) {
  let { gameID } = useParams();
  const [game, setGame] = useState();
  const [homeManager, setHomeManager] = useState();
  const [awayManager, setAwayManager] = useState();
  const [getGame, { data, subscribeToMore }] = useLazyQuery(GAME_BY_ID);

  useEffect(() => {
    if (gameID) {
      getGame({ variables: { id: gameID } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameID]);

  useEffect(() => {
    let unsubscribe;
    if (subscribeToMore) {
      console.log("Subscribed");
      unsubscribe = subscribeToMore({
        document: GAME_SUBSCRIPTION,
        variables: { id: gameID },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;

          const updatedGame = subscriptionData.data.gameUpdated;
          let newGame = {
            gameById: { ...prev.gameById, ...updatedGame },
          };

          return newGame;
        },
      });
    }
    return () => {
      console.log("Unsubscribed");
      unsubscribe && unsubscribe();
    };
  }, [subscribeToMore, gameID]);

  useEffect(() => {
    if (data) {
      setGame(data.gameById);
    }
  }, [data]);

  const { updateGame } = useContext(APIContext);

  if (!game) return <div>Loading</div>;
  return (
    game && (
      <Main>
        <Content>
          <TeamLogger
            setSelectedPlayer={setSelectedPlayer}
            homeTeam={game.home_team}
            awayTeam={game.away_team}
            game={game}
          />
          <Row>
            <Column>
              Game 1
              <Row>
                <StatBox
                  category="Shots On target"
                  variable="shots_on_target"
                  updateGame={updateGame}
                  game={game}
                  game_num={1}
                ></StatBox>
              </Row>
              <Row>
                <StatBox
                  category="Shots Off target"
                  variable="shots_off_target"
                  updateGame={updateGame}
                  game={game}
                  game_num={1}
                ></StatBox>
              </Row>
              <Row>
                <StatBox
                  category="Offside"
                  variable="offside"
                  updateGame={updateGame}
                  game={game}
                  game_num={1}
                ></StatBox>
              </Row>
              <Row>
                <StatBox
                  category="Saves"
                  variable="saves"
                  updateGame={updateGame}
                  game={game}
                  game_num={1}
                ></StatBox>
              </Row>
              <Row>
                <StatBox
                  category="Fouls"
                  variable="fouls"
                  updateGame={updateGame}
                  game={game}
                  game_num={1}
                ></StatBox>
              </Row>
              <Row>
                <StatBox
                  category="Corners"
                  variable="corners"
                  updateGame={updateGame}
                  game={game}
                  game_num={1}
                ></StatBox>
              </Row>
              <Row>
                <StatBox
                  category="Yellow Cards"
                  variable="yellow_cards"
                  updateGame={updateGame}
                  game={game}
                  game_num={1}
                ></StatBox>
              </Row>
              <Row>
                <StatBox
                  category="Red Cards"
                  variable="red_cards"
                  updateGame={updateGame}
                  game={game}
                  game_num={1}
                ></StatBox>
              </Row>
            </Column>
            <Column>
              Game 2
              <Row>
                <StatBox
                  category="Shots On target"
                  variable="shots_on_target"
                  updateGame={updateGame}
                  game={game}
                  game_num={2}
                ></StatBox>
              </Row>
              <Row>
                <StatBox
                  category="Shots Off target"
                  variable="shots_off_target"
                  updateGame={updateGame}
                  game={game}
                  game_num={2}
                ></StatBox>
              </Row>
              <Row>
                <StatBox
                  category="Offside"
                  variable="offside"
                  updateGame={updateGame}
                  game={game}
                  game_num={2}
                ></StatBox>
              </Row>
              <Row>
                <StatBox
                  category="Saves"
                  variable="saves"
                  updateGame={updateGame}
                  game={game}
                  game_num={2}
                ></StatBox>
              </Row>
              <Row>
                <StatBox
                  category="Fouls"
                  variable="fouls"
                  updateGame={updateGame}
                  game={game}
                  game_num={2}
                ></StatBox>
              </Row>
              <Row>
                <StatBox
                  category="Corners"
                  variable="corners"
                  updateGame={updateGame}
                  game={game}
                  game_num={2}
                ></StatBox>
              </Row>
              <Row>
                <StatBox
                  category="Yellow Cards"
                  variable="yellow_cards"
                  updateGame={updateGame}
                  game={game}
                  game_num={2}
                ></StatBox>
              </Row>
              <Row>
                <StatBox
                  category="Red Cards"
                  variable="red_cards"
                  updateGame={updateGame}
                  game={game}
                  game_num={2}
                ></StatBox>
              </Row>
            </Column>
          </Row>
        </Content>
      </Main>
    )
  );
}
const MainBox = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: space-around;
`;
const Title = styled.div`
  display: flex;
  min-width: 200px;
  text-align: center;
  justify-content: center;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
function StatBox({ category, variable, updateGame, game, game_num }) {
  return (
    <MainBox>
      <Row>
        <Button
          style={{ padding: "20px", margin: "10px" }}
          onClick={() => {
            let newData = { ...JSON.parse(JSON.stringify(game)) };

            let home_stats = { ...newData.home_stats };
            home_stats["game_" + game_num] =
              home_stats?.["game_" + game_num] || {};

            home_stats["game_" + game_num][variable] = Math.max(
              (home_stats["game_" + game_num]?.[variable] || 0) - 1,
              0
            );
            updateGame({ id: game._id, home_stats });
          }}
        >
          -
        </Button>
        {game?.home_stats?.["game_" + game_num]?.[variable] || 0}
        <Button
          style={{ padding: "20px", margin: "10px" }}
          onClick={() => {
            let newData = { ...JSON.parse(JSON.stringify(game)) };

            let home_stats = { ...newData.home_stats };
            home_stats["game_" + game_num] =
              home_stats?.["game_" + game_num] || {};
            home_stats["game_" + game_num][variable] = Math.max(
              (home_stats?.["game_" + game_num]?.[variable] || 0) + 1,
              0
            );
            updateGame({ id: game._id, home_stats });
          }}
        >
          +
        </Button>
        <Title>{category}</Title>
        <Button
          style={{ padding: "20px", margin: "10px" }}
          onClick={() => {
            let newData = { ...JSON.parse(JSON.stringify(game)) };

            let away_stats = { ...newData.away_stats };
            away_stats["game_" + game_num] =
              away_stats?.["game_" + game_num] || {};
            away_stats["game_" + game_num][variable] = Math.max(
              (away_stats?.["game_" + game_num]?.[variable] || 0) - 1,
              0
            );
            updateGame({ id: game._id, away_stats });
          }}
        >
          -
        </Button>
        {game?.away_stats?.["game_" + game_num]?.[variable] || 0}
        <Button
          style={{ padding: "20px", margin: "10px" }}
          onClick={() => {
            let newData = { ...JSON.parse(JSON.stringify(game)) };

            let away_stats = { ...newData.away_stats };
            away_stats["game_" + game_num] =
              away_stats?.["game_" + game_num] || {};
            away_stats["game_" + game_num][variable] = Math.max(
              (away_stats?.["game_" + game_num]?.[variable] || 0) + 1,
              0
            );
            updateGame({ id: game._id, away_stats });
          }}
        >
          +
        </Button>
      </Row>
    </MainBox>
  );
}
