export class PlayerHeartRate {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "PlayerHeartRate";
    this.scene_left = "FeNC_L3_NameHeartrate";
    this.scene_right = "FeNC_L3_NameHeartrate_RIGHT";
    this.data = [];
  }

  preview({ options }) {
    this.onAir = false;
    this.onPreview = true;
    this.data = [];
    this.options = options;
    if (options.left) {
      this.scene = this.scene_left;
    } else {
      this.scene = this.scene_right;
    }
    this.data.push({
      name: "TopTEXT",
      value: {
        text: options?.player?.first_name || "",
      },
    });

    this.data.push({
      name: "BottTEXT",
      value: {
        text: options?.team?.name?.toUpperCase() || "",
      },
    });

    this.data.push({
      name: "Flag",
      value: {
        image: options?.team?.code || "",
      },
    });

    this.data.push({
      name: options.left ? "HRate" : "BPM",
      value: {
        text:
          options?.rates
            ?.find(
              (h) =>
                h.hyperId ===
                this.options?.player?.squad?.player?.first_name_upper
            )
            ?.data?.bpm?.toString() || "   ",
      },
    });

    this.sendPreview(this.scene, 500, this.data, "IN");
  }
  animate({ options }) {
    this.onAir = true;
    this.onPreview = false;
    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.onPreview = false;
    this.sendAnimateOff(this.scene, []);
  }

  update({ options }) {
    if (this.onAir || this.onPreview) {
      let heart_data = [];

      heart_data.push({
        name: this.options.left ? "HRate" : "BPM",
        value: {
          text:
            options?.rates
              ?.find(
                (h) =>
                  h.hyperId ===
                  this.options?.player?.squad?.player?.first_name_upper
              )
              ?.data?.bpm?.toString() || "   ",
        },
      });
      this.sendUpdate(this.scene, heart_data, options?.engine);
    }
  }
}
