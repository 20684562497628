export class QFMatchUps {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "QFMatchUps";
    this.scene = "FeNC_FF_QF_MatchUps";
    this.data = [];
  }

  preview({ options }) {
    this.onAir = false;
    this.data = [];

    options.games.forEach((game, index) => {
      let row = index + 1;
      let game1_complete =
        game?.fifa_result[0].status !== "upcoming" &&
        (game?.fifa_result[0].result_type === "full-time" ||
          game?.fifa_result[0].result_type === "penalties" ||
          game?.fifa_result[0].result_type === "extra-time");

      let game2_complete =
        game?.fifa_result[1].status !== "upcoming" &&
        (game?.fifa_result[1].result_type === "full-time" ||
          game?.fifa_result[1].result_type === "penalties" ||
          game?.fifa_result[1].result_type === "extra-time");

      this.data.push({
        name: "AET_Line_" + row,
        value: {
          text:
            game?.fifa_result[1].result_type === "penalties"
              ? "PSO"
              : game?.fifa_result[1].result_type === "extra-time"
              ? "AET"
              : "",
        },
      });

      this.data.push({
        name: "L" + row + "_H_Name",
        value: {
          text: game?.home_team?.name?.toUpperCase() || "",
        },
      });
      this.data.push({
        name: "L" + row + "_A_Name",
        value: {
          text: game?.away_team?.name?.toUpperCase() || "",
        },
      });
      this.data.push({
        name: "L" + row + "_H_Flag",
        value: {
          image: game?.home_team?.code || "",
        },
      });
      this.data.push({
        name: "L" + row + "_A_Flag",
        value: {
          image: game?.away_team?.code || "",
        },
      });
      this.data.push({
        name: "L" + row + "_H_Score_1",
        value: {
          text: game1_complete
            ? game?.home_scorers?.filter((g) => g.game === 1)?.length
            : "-",
        },
      });
      this.data.push({
        name: "L" + row + "_H_Score_2",
        value: {
          text: game2_complete
            ? game?.home_scorers?.filter((g) => g.game === 2)?.length
            : "-",
        },
      });
      this.data.push({
        name: "L" + row + "_A_Score_1",
        value: {
          text: game1_complete
            ? game?.away_scorers?.filter((g) => g.game === 1)?.length
            : "-",
        },
      });
      this.data.push({
        name: "L" + row + "_A_Score_2",
        value: {
          text: game2_complete
            ? game?.away_scorers?.filter((g) => g.game === 2)?.length
            : "-",
        },
      });
    });

    this.sendPreview(this.scene, 500, this.data, "IN");
  }
  animate({ options }) {
    this.onAir = true;

    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }
}
