export class Clock {
  constructor({ animate, preview, update, animateOff, triggerAnimation }) {
    this.onAir = false;
    this.time = 0;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.triggerAnimation = triggerAnimation;
    this.name = "CLOCK";
    this.data = [];
  }

  preview({ options }) {
    this.onAir = false;
    this.data = [
      { name: "Home Team", value: { text: "" } },
      { name: "Away Team", value: { text: "" } },
      { name: "Game Clock", value: { text: options.clock } },
      { name: "Game Clock_2", value: { text: options.clock } },
    ];
    this.sendPreview("ScoreBug", 25, this.data, "IN");
    this.time++;
  }
  animate({ options }) {
    debugger;
    this.onAir = true;
    this.added_time_on_air = false;
    this.data = [
      {
        name: "CTRL_HALF",
        value: { text: options.period.number },
      },
      {
        name: "EXTRA_TIME_GRP",
        value: { visible: options.period.number > 2 ? true : false },
      },
      { name: "Home Team", value: { text: options.game.home_team.code } },
      { name: "Away Team", value: { text: options.game.away_team.code } },
      {
        name: "LEFT_TEAM_COLOUR_1",
        value: {
          colour:
            options.game.home_team.variables[
              options.game?.home_team_kit + "_kit_hex_1"
            ] &&
            options.game.home_team.variables[
              options.game?.home_team_kit + "_kit_hex_1"
            ].replace("#", "0x"),
        },
      },
      {
        name: "LEFT_TEAM_COLOUR_2",
        value: {
          colour:
            options.game.home_team.variables[
              options.game?.home_team_kit + "_kit_hex_2"
            ] &&
            options.game.home_team.variables[
              options.game?.home_team_kit + "_kit_hex_2"
            ].replace("#", "0x"),
        },
      },
      {
        name: "RIGHT_TEAM_COLOUR_1",
        value: {
          colour:
            options.game.away_team.variables[
              options.game?.away_team_kit + "_kit_hex_1"
            ] &&
            options.game.away_team.variables[
              options.game?.away_team_kit + "_kit_hex_1"
            ].replace("#", "0x"),
        },
      },
      {
        name: "RIGHT_TEAM_COLOUR_2",
        value: {
          colour:
            options.game.away_team.variables[
              options.game?.away_team_kit + "_kit_hex_2"
            ] &&
            options.game.away_team.variables[
              options.game?.away_team_kit + "_kit_hex_2"
            ].replace("#", "0x"),
        },
      },
      { name: "Game Clock", value: { text: options.clock } },
      { name: "Game Clock_2", value: { text: options.clock } },
      {
        name: "Home Score",
        value: {
          text:
            (options.game.live_data &&
              options.game.live_data.home_team_score) ||
            0,
        },
      },
      {
        name: "Away Score",
        value: {
          text:
            (options.game.live_data &&
              options.game.live_data.away_team_score) ||
            0,
        },
      },
    ];
    this.sendAnimate("ScoreBug", this.data);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.added_time_on_air = false;
    this.sendAnimateOff("ScoreBug", []);
  }
  update({ options }) {
    if (options.type === "ADDED_TIME") {
      this.sendUpdate("ScoreBug", [
        { name: "AT_TEXT", value: { text: "+" + options.added_time } },
      ]);
      if (!this.added_time_on_air) {
        this.added_time_on_air = true;
        this.triggerAnimation(this.name, [], "T6");
      }
    } else {
      let home_reds = options.game.live_data?.home_team_lineup?.reduce(
        (prev, curr) => {
          return (prev += curr.red_card || curr.dbl_yellow_card ? 1 : 0);
        },
        0
      );

      let away_reds = options.game.live_data?.away_team_lineup?.reduce(
        (prev, curr) => {
          return (prev += curr.red_card || curr.dbl_yellow_card ? 1 : 0);
        },
        0
      );

      let data = [
        {
          name: "RED_CARD_HOME_1",
          value: { visible: home_reds > 0 ? true : false },
        },
        {
          name: "RED_CARD_HOME_2",
          value: { visible: home_reds > 1 ? true : false },
        },
        {
          name: "RED_CARD_HOME_3",
          value: { visible: home_reds > 2 ? true : false },
        },
        {
          name: "RED_CARD_AWAY_1",
          value: { visible: away_reds > 0 ? true : false },
        },
        {
          name: "RED_CARD_AWAY_2",
          value: { visible: away_reds > 1 ? true : false },
        },
        {
          name: "RED_CARD_AWAY_3",
          value: { visible: away_reds > 2 ? true : false },
        },
        { name: "Game Clock", value: { text: options.clock } },
        { name: "Game Clock_2", value: { text: options.clock } },
        {
          name: "Home Score",
          value: {
            text:
              (options.game.live_data &&
                options.game.live_data.home_team_score) ||
              0,
          },
        },
        {
          name: "Away Score",
          value: {
            text:
              (options.game.live_data &&
                options.game.live_data.away_team_score) ||
              0,
          },
        },
      ];

      // this.sendUpdate("ScoreBug", data);
    }
  }
}
