export class MatchScore {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "MatchScore";
    this.scene = "FeNC_FF_HT_FT";
    this.data = [];
  }

  preview({ options }) {
    this.onAir = false;
    this.data = [];

    let left_team =
      options.teamSides === 0
        ? options.game?.home_team
        : options.game?.away_team;

    let right_team =
      options.teamSides === 1
        ? options.game?.home_team
        : options.game?.away_team;

    let left_score =
      options.teamSides === 0
        ? options.game?.home_scorers?.filter((s) => s.game === options.game_num)
            ?.length || 0
        : options.game?.away_scorers?.filter((s) => s.game === options.game_num)
            ?.length || 0;

    let right_score =
      options.teamSides === 1
        ? options.game?.home_scorers?.filter((s) => s.game === options.game_num)
            ?.length || 0
        : options.game?.away_scorers?.filter((s) => s.game === options.game_num)
            ?.length || 0;

    let left_lineup =
      options.teamSides === 0
        ? options.game.lineup?.home
        : options.game.lineup?.away;

    let right_lineup =
      options.teamSides === 1
        ? options.game.lineup?.home
        : options.game.lineup?.away;

    this.data.push({
      name: "FinalLoopGRP",
      value: {
        visible: options.game?.fifa_position === 15,
      },
    });
    this.data.push({
      name: "SemiLoopGRP",
      value: {
        visible: options.game?.fifa_position !== 15,
      },
    });
    this.data.push({
      name: "Header",
      value: {
        text: options.half === 1 ? "HALF-TIME" : "FULL-TIME",
      },
    });
    this.data.push({
      name: "HeaderOutline",
      value: {
        text: options.half === 1 ? "HALF-TIME" : "FULL-TIME",
      },
    });
    this.data.push({
      name: "LH_TeamName",
      value: {
        text: left_team?.name || "",
      },
    });
    this.data.push({
      name: "RH_TeamName",
      value: {
        text: right_team?.name || "",
      },
    });
    this.data.push({
      name: "LH_Flag",
      value: {
        image: left_team?.code || "",
      },
    });
    this.data.push({
      name: "RH_Flag",
      value: {
        image: right_team?.code || "",
      },
    });
    this.data.push({
      name: "Home_Score",
      value: {
        text: left_score,
      },
    });
    this.data.push({
      name: "Away_Score",
      value: {
        text: right_score,
      },
    });

    let left_names = "";
    left_lineup?.forEach((player, index) => {
      left_names += player?.squad?.player?.first_name;
      if (index < left_lineup.length - 1) {
        left_names += " + ";
      }
    });
    this.data.push({
      name: "Left_PlayerNames",
      value: {
        text: left_names,
      },
    });

    left_lineup?.forEach((player, index) => {
      this.data.push({
        name: "Left_Headshot_" + (index + 1),
        value: {
          image: player?.squad?.player?.last_name_upper,
        },
      });
    });

    let right_names = "";
    right_lineup?.forEach((player, index) => {
      right_names += player?.squad?.player?.first_name;
      if (index < right_lineup.length - 1) {
        right_names += " + ";
      }
    });
    this.data.push({
      name: "Right_PlayerNames",
      value: {
        text: right_names,
      },
    });

    right_lineup?.forEach((player, index) => {
      this.data.push({
        name: "Right_Headshot_" + (index + 1),
        value: {
          image: player?.squad?.player?.last_name_upper,
        },
      });
    });

    this.sendPreview(this.scene, 500, this.data, "IN");
  }
  animate({ options }) {
    this.onAir = true;

    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }
}
