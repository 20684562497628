export class TeamTicker {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "TeamTicker";
    this.scene = "Team Ticker";
    this.data = [];
    this.page = 1;
  }

  preview({ options }) {
    if (options.start) {
      this.page = 1;
    }
    this.onAir = false;
    this.data = [];
    this.options = options;

    let team_type =
      options?.game?.home_team?._id === options.team?._id ? "home" : "away";

    this.data.push({
      name: "Title",
      value: {
        text:
          options.game?.[team_type + "_formation"]?.rows?.substring(1) || "",
      },
    });

    let count = 0;
    for (let i = 0; i < this.page - 1; i++) {
      count += parseInt(options.game?.[team_type + "_formation"]?.rows?.[i]);
    }

    new Array(5).fill(1).forEach((_, index) => {
      let row = index + 1;
      let player = {};

      let number_in_row =
        options.game?.[team_type + "_formation"]?.rows?.[this.page - 1];
      console.log("number in row", number_in_row);
      if (number_in_row > index) {
        console.log("player index", count + index + 1);
        player = options.squads?.find((s) => s.order === count + index + 1);
      }

      this.data.push({
        name: "First Name " + row,
        value: {
          text: player?.squad?.player?.first_name || "",
        },
      });
      this.data.push({
        name: "Last Name " + row,
        value: {
          text: player?.squad?.player?.last_name || "",
        },
      });
      this.data.push({
        name: "First Name Uppercase " + row,
        value: {
          text: player?.squad?.player?.first_name_upper || "",
        },
      });
      this.data.push({
        name: "Last Name Uppercase " + row,
        value: {
          text: player?.squad?.player?.last_name_upper || "",
        },
      });
      this.data.push({
        name: "Full Name " + row,
        value: {
          text:
            (player?.squad?.player?.first_name || "") +
            " " +
            (player?.squad?.player?.last_name || ""),
        },
      });
      this.data.push({
        name: "Full Name Uppercase" + row,
        value: {
          text:
            (player?.squad?.player?.first_name_upper || "") +
            " " +
            (player?.squad?.player?.last_name_upper || ""),
        },
      });
      this.data.push({
        name: "Captain " + row,
        value: {
          visible: player?.captain || false,
        },
      });
      this.data.push({
        name: "Position " + row,
        value: {
          text: player?.squad?.position || "",
        },
      });
      this.data.push({
        name: "Shirt Number " + row,
        value: {
          text: player?.squad?.shirt_number || "",
        },
      });
    });

    this.data.push({
      name: "Team Name",
      value: {
        text: options.team?.name,
      },
    });

    this.data.push({
      name: "Team Short Name",
      value: {
        text: options.team?.short_name,
      },
    });

    this.data.push({
      name: "Team Badge",
      value: {
        image: options.team?.variables?.badge,
      },
    });

    this.data.push({
      name: "Team Secondary Badge",
      value: {
        image: options.team?.variables?.alt_badge,
      },
    });

    this.data.push({
      name: "Team Video Badge",
      value: {
        video: options.team?.variables?.video_badge,
      },
    });

    this.sendPreview(this.scene, 500, this.data, "IN");
  }
  animate({ options }) {
    this.onAir = true;
    this.onAir_scene = this.scene;
    this.sendAnimate(this.scene, this.data);

    this.page++;
    this.options.start = false;
    this.preview({ options: this.options });
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff(this.onAir_scene, []);
    this.page = 1;
  }
}
