import React, { useState, useEffect, useContext, useCallback } from "react";

const HeartRateContext = React.createContext([{}, () => {}]);

window.heart_ws = null;
let heart_rates = [];
const HeartRateProvider = (props) => {
  const [ws, setWS] = useState(null);
  const [data, setData] = useState([]);
  const [wsStatus, setWSStatus] = useState(-1);

  useEffect(() => {
    connect();
  }, []);

  function connect() {
    console.log("Heart Rate connecting to server");

    window.heart_ws = new WebSocket("wss://clk1.hyper.live");
    setWS(window.heart_ws);
  }

  useEffect(() => {
    if (window.heart_ws) {
      let timeout;
      window.heart_ws.onopen = () => {
        setWSStatus(1);
        console.log("HR  on open");
        clearInterval(window.keep_alive);
        window.keep_alive = setInterval(() => {
          window.heart_ws.send(
            JSON.stringify({
              keep_alive: Date.now(),
              group: window.id,
            })
          );
        }, 20000);
        sendData({
          group: window.id,
        });
      };
      window.heart_ws.onmessage = (data) => {
        try {
          let obj = JSON.parse(data.data);
          if (!obj.keep_alive) {
            let new_rates = [...heart_rates];
            let index = new_rates.findIndex((hr) => hr.id === obj.id);
            if (index === -1) {
              new_rates.push(obj);
            } else {
              new_rates[index] = obj;
            }
            heart_rates = new_rates;
            setData(heart_rates);
          }
        } catch (err) {
          console.error(err);
        }
      };
      window.heart_ws.onerror = (err) => {
        ws.close();
      };
      window.heart_ws.onclose = (data) => {
        setWSStatus(0);
        console.log("HR on close");
        clearTimeout(timeout);
        clearInterval(window.keep_alive);
        timeout = setTimeout(() => {
          connect();
        }, 1000);
      };
    }
  }, [ws, setData]);

  function sendData(data) {
    try {
      if (window.heart_ws) {
        data.user = {
          id: window.id,
        };

        data.group = localStorage.getItem("user-id");
        //data.app = "football";
        window.heart_ws.send(JSON.stringify({ ...data, from_control: true }));
      }
    } catch (err) {}
  }

  return (
    <HeartRateContext.Provider
      value={{
        wsStatus,
        data,
      }}
    >
      {props.children}
    </HeartRateContext.Provider>
  );
};

export { HeartRateContext, HeartRateProvider };
