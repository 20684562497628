import React, { useContext, useEffect, useState } from "react";
import HyperLogo from "../../Images/HyperLogo.png";
import styled from "styled-components";
import Clock from "./Clock";
import Player from "./Player";
import Subs from "../Subs/Subs";
import EndHalf from "./EndHalf";
import moment from "moment";
import { Button } from "../../Theme/Hyper";
import { UserContext } from "../../contexts/UserContext";
import { APIContext } from "../../contexts/APIContext";
import { UIContext } from "../../contexts/UIContext";

const Main = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 0.5em;
  background-color: #202731;
  box-sizing: border-box;
  flex-direction: column;
  position: relative;
`;
const Logo = styled.img`
  height: auto;
  width: 50%;
`;

const GraphicButton = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) =>
    props.green ? "#0adb93" : props.red ? "#db0a41" : "#181d25"};
  color: ${(props) => (props.green ? "#1d1d1d" : "")};
  height: 40px;
  width: 90%;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 1em;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :hover {
    background-color: ${(props) =>
      props.green ? "#0adb93" : props.red ? "#db0a41" : "#db0a41"};
  }
`;
const CurrentGraphic = styled.div`
  margin-top: auto;
  width: 100%;
  height: 167px;
  background-color: #181d25;
  box-sizing: border-box;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 0.5em;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  bottom: 12px;
  margin: 0px;
  padding-bottom: 10px;
`;
const Buttons = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  font-size: 18px;
`;
const NavButton = styled(Button)`
  margin: 2px;
  padding: 5px;
  background-color: ${(props) => (props.selected ? "#0adb93" : "")};
  color: ${(props) => (props.selected ? "#1d1d1d" : "")};
`;

const RoundButton = styled(Button)`
  margin: 0.5em;
  background-color: ${(props) => (props.selected ? "#0adb93" : "")};
  color: ${(props) => (props.selected ? "#1d1d1d" : "")};
`;

const Profile = styled.div`
  width: 100%;
  height: 35px;
  background-color: #202731;
  z-index: 1000;
  position: -webkit-sticky;
  position: sticky;
  box-shadow: 0px 4px 12px -20px rgb(0 0 0 / 20%),
    0px 8px 20px 2px rgb(0 0 0 / 14%), 0 9px 46px 8px rgb(0 0 0 / 12%);
  display: flex;
  align-items: center;
  border-bottom: 1px solid #030304;
  padding-left: 5px;
  padding-right: 5px;
  box-sizing: border-box;
  padding-bottom: 5px;
  margin-bottom: 10px;
`;
export default function RightSideBar({
  game,
  updateGame,
  previewGraphic,
  graphicInPreview,
  animateGraphic,
  animateOffGraphic,
  updateGraphic,
  teams,
  selectedPlayer,
  players,
  stadiums,
  sky,
  squads,
  countries,
  standings,
  kits,
  clearPreview,
  homeManager,
  awayManager,
}) {
  const { user, signOut } = useContext(UserContext);
  const { getGroup, getAllFixtures, getGroupByCompetition } =
    useContext(APIContext);
  const { teamSides } = useContext(UIContext);

  const [groups, setGroups] = useState([]);
  const [fixtures, setFixtures] = useState([]);
  const [view, setView] = useState(0);

  useEffect(() => {
    getGroup().then((data) => {
      setGroups(data);
    });
    getAllFixtures({}).then((data) => {
      setFixtures(data);
    });
  }, []);

  return (
    <Main>
      {!window.hideHeader && (
        <Profile>
          {user?.username}
          <Button
            style={{ border: "1px solid #fff", marginLeft: "auto" }}
            onClick={() => {
              signOut();
            }}
          >
            Sign Out
          </Button>
        </Profile>
      )}
      <Logo src={HyperLogo}></Logo>
      <Buttons>
        <NavButton
          selected={view === 0}
          onClick={() => {
            setView(0);
          }}
        >
          Tables
        </NavButton>
        <NavButton
          selected={view === 2}
          onClick={() => {
            setView(2);
          }}
        >
          Match-Ups
        </NavButton>
        <NavButton
          selected={view === 1}
          onClick={() => {
            setView(1);
          }}
        >
          Round Scores
        </NavButton>
        <NavButton
          selected={view === 3}
          onClick={() => {
            setView(3);
          }}
        >
          Subs
        </NavButton>
      </Buttons>
      {view === 0 && groups && (
        <GraphicButton
          onClick={() => {
            getGroupByCompetition(game?.competition?._id).then((data) => {
              previewGraphic("Group", {
                game: game,
                group: data?.filter((g) => g.group === "Group A"),
              });
            });
          }}
        >
          Group A
        </GraphicButton>
      )}
      {view === 0 && groups && (
        <GraphicButton
          onClick={() => {
            getGroupByCompetition(game?.competition?._id).then((data) => {
              previewGraphic("Group", {
                game: game,
                group: data?.filter((g) => g.group === "Group B"),
              });
            });
          }}
        >
          Group B
        </GraphicButton>
      )}
      {view === 0 && groups && (
        <GraphicButton
          onClick={() => {
            getGroupByCompetition(game?.competition?._id).then((data) => {
              previewGraphic("Group", {
                game: game,
                group: data?.filter((g) => g.group === "Group C"),
              });
            });
          }}
        >
          Group C
        </GraphicButton>
      )}
      {view === 0 && groups && (
        <GraphicButton
          onClick={() => {
            getGroupByCompetition(game?.competition?._id).then((data) => {
              previewGraphic("Group", {
                game: game,
                group: data?.filter((g) => g.group === "Group D"),
              });
            });
          }}
        >
          Group D
        </GraphicButton>
      )}
      {view === 0 && groups && (
        <GraphicButton
          onClick={() => {
            getAllFixtures({}).then((data) => {
              previewGraphic("Bracket", {
                games: data?.filter(
                  (g) =>
                    g.competition?.name === game?.competition?.name &&
                    g.round_type === "knockout_match"
                ),
                left: true,
                final: false,
              });
            });
          }}
        >
          Bracket Left
        </GraphicButton>
      )}
      {view === 0 && groups && (
        <GraphicButton
          onClick={() => {
            getAllFixtures({}).then((data) => {
              previewGraphic("Bracket", {
                games: data?.filter(
                  (g) =>
                    g.competition?.name === game?.competition?.name &&
                    g.round_type === "knockout_match"
                ),
                left: false,
                final: false,
              });
            });
          }}
        >
          Bracket Right
        </GraphicButton>
      )}
      {view === 0 && groups && (
        <GraphicButton
          onClick={() => {
            getAllFixtures({}).then((data) => {
              previewGraphic("Bracket", {
                games: data?.filter(
                  (g) =>
                    g.competition?.name === game?.competition?.name &&
                    g.round_type === "knockout_match"
                ),
                final: true,
              });
            });
          }}
        >
          Bracket Final
        </GraphicButton>
      )}
      {view === 0 && groups && (
        <GraphicButton
          onClick={() => {
            getAllFixtures({}).then((data) => {
              previewGraphic("NextUp", {
                game: game,
                clock: true,
                teamSides,
              });
            });
          }}
        >
          Next Up
        </GraphicButton>
      )}
      {view === 0 && groups && (
        <GraphicButton
          onClick={() => {
            getAllFixtures({}).then((data) => {
              previewGraphic("NextUp", {
                game: game,
                clock: false,
                teamSides,
              });
            });
          }}
        >
          Next Up No Clock
        </GraphicButton>
      )}
      {view === 0 && groups && (
        <GraphicButton
          onClick={() => {
            previewGraphic("PlayerCam1", {
              game: game,
            });
          }}
        >
          1 Player Cam A
        </GraphicButton>
      )}
      {view === 0 && groups && (
        <GraphicButton
          onClick={() => {
            previewGraphic("PlayerCam1", {
              game: game,
              switch: true,
            });
          }}
        >
          1 Player Cam B
        </GraphicButton>
      )}
      {view === 0 && groups && (
        <GraphicButton
          onClick={() => {
            previewGraphic("PlayerCam2", {
              game: game,
            });
          }}
        >
          2 Player Cam A
        </GraphicButton>
      )}
      {view === 0 && groups && (
        <GraphicButton
          onClick={() => {
            previewGraphic("PlayerCam2", {
              game: game,
              switch: true,
            });
          }}
        >
          2 Player Cam B
        </GraphicButton>
      )}
      {view === 1 && (
        <>
          <div>Group A</div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            {new Array(10).fill(1).map((_, index) => {
              return (
                <RoundButton
                  onClick={() => {
                    previewGraphic("GroupResults", {
                      group: "GROUP A",
                      round: "ROUND " + (index + 1),
                      games: fixtures?.filter(
                        (g) => g.round === index + 1 && g.group === 1
                      ),
                    });
                  }}
                >
                  R{index + 1}
                </RoundButton>
              );
            })}
          </div>
        </>
      )}
      {view === 1 && (
        <>
          <div>Group B</div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            {new Array(10).fill(1).map((_, index) => {
              return (
                <RoundButton
                  onClick={() => {
                    previewGraphic("GroupResults", {
                      group: "GROUP B",
                      round: "ROUND " + (index + 1),
                      games: fixtures?.filter(
                        (g) => g.round === index + 1 && g.group === 2
                      ),
                    });
                  }}
                >
                  R{index + 1}
                </RoundButton>
              );
            })}
          </div>
        </>
      )}
      {view === 1 && (
        <>
          <div>Group C</div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            {new Array(10).fill(1).map((_, index) => {
              return (
                <RoundButton
                  onClick={() => {
                    previewGraphic("GroupResults", {
                      group: "GROUP C",
                      round: "ROUND " + (index + 1),
                      games: fixtures?.filter(
                        (g) => g.round === index + 1 && g.group === 3
                      ),
                    });
                  }}
                >
                  R{index + 1}
                </RoundButton>
              );
            })}
          </div>
        </>
      )}
      {view === 1 && (
        <>
          <div>Group D</div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            {new Array(10).fill(1).map((_, index) => {
              return (
                <RoundButton
                  onClick={() => {
                    previewGraphic("GroupResults", {
                      group: "GROUP D",
                      round: "ROUND " + (index + 1),
                      games: fixtures?.filter(
                        (g) => g.round === index + 1 && g.group === 4
                      ),
                    });
                  }}
                >
                  R{index + 1}
                </RoundButton>
              );
            })}
          </div>
        </>
      )}

      {view === 2 && (
        <GraphicButton
          onClick={() => {
            getAllFixtures({}).then((data) => {
              previewGraphic("QFMatchUps", {
                games: data?.filter(
                  (g) =>
                    g.competition?.name === game?.competition?.name &&
                    g.round_type === "knockout_match" &&
                    g.fifa_position > 8 &&
                    g.fifa_position <= 12
                ),
              });
            });
          }}
        >
          QF Match Ups
        </GraphicButton>
      )}
      {view === 2 && (
        <GraphicButton
          onClick={() => {
            getAllFixtures({}).then((data) => {
              previewGraphic("SFMatchUps", {
                games: data?.filter(
                  (g) =>
                    g.competition?.name === game?.competition?.name &&
                    g.round_type === "knockout_match" &&
                    (g.fifa_position === 13 || g.fifa_position === 14)
                ),
              });
            });
          }}
        >
          SF Match Ups
        </GraphicButton>
      )}
      {view === 2 && (
        <GraphicButton
          onClick={() => {
            getAllFixtures({}).then((data) => {
              previewGraphic("FinalMatchUps", {
                games: data?.filter(
                  (g) =>
                    g.competition?.name === game?.competition?.name &&
                    g.round_type === "knockout_match" &&
                    g.fifa_position === 15
                ),
              });
            });
          }}
        >
          Final Match Up
        </GraphicButton>
      )}
      {view === 3 &&
        (game.lineup?.home?.filter((p) => p.sub_order).length > 0 ||
          game.lineup?.away?.filter((p) => p.sub_order).length > 0) && (
          <Subs
            sky={sky}
            countries={countries}
            previewGraphic={previewGraphic}
            game={game}
            teams={teams}
            players={players}
            homeSubs={game.lineup?.home?.filter((p) => p.sub_order)}
            awaySubs={game.lineup?.away?.filter((p) => p.sub_order)}
          />
        )}

      {graphicInPreview && (
        <CurrentGraphic
          style={{ bottom: window.hideHeader === true ? "40px" : "237px" }}
        >
          {graphicInPreview.name}
          <GraphicButton
            style={{ height: "40px", backgroundColor: "#383f4a" }}
            onClick={() => {
              clearPreview(graphicInPreview.name);
            }}
          >
            CLEAR PVW (CTRL + Q)
          </GraphicButton>

          <GraphicButton
            green
            style={{ height: "40px" }}
            onClick={() => {
              animateGraphic(graphicInPreview.name);
            }}
          >
            IN (F8) or (-)
          </GraphicButton>
          <GraphicButton
            red
            style={{ height: "40px" }}
            onClick={() => {
              animateOffGraphic(graphicInPreview.name);
            }}
          >
            OUT (F9) or (*)
          </GraphicButton>
        </CurrentGraphic>
      )}
    </Main>
  );
}
