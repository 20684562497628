import React from "react";
import styled from "styled-components";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
const Main = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5em;
  background-color: #181d25;
  border: ${(props) => (props.selected ? "2px solid #0adb93" : "")};
  border-radius: 4px;
  margin: 2px;
  cursor: pointer;
  /* flex-direction: column; */
`;

const Player = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5em;
  width: 100%;
`;
const Off = styled(IoIosArrowDown)`
  color: #db0a41;
  margin-right: 0.5em;
`;
const On = styled(IoIosArrowUp)`
  color: #0adb93;
  margin-right: 0.5em;
`;

export default function Sub({
  players,
  previewGraphic,
  countries,
  sky,
  team,
  game,
}) {
  return (
    <Main
      onClick={(e) => {
        previewGraphic("Substitution", {
          players: players,
          countries: countries,
          sky: sky,
          team: team,
          game: game,
          start: true,
        });
      }}
    >
      {players &&
        players.map((player) => {
          return (
            <Player>
              {player.order <= 11 && <Off />}
              {player.order > 11 && <On />}
              {player?.squad?.player?.first_name}
            </Player>
          );
        })}
    </Main>
  );
}
