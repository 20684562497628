export class PlayerScored {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "PlayerScored";
    this.scene = "Player Scored";
    this.data = [];
  }

  preview({ options }) {
    this.onAir = false;
    this.data = [];
    let goalTime = "";

    if (options.time > 0) {
      goalTime = options.time + "'";
    }

    this.data.push({
      name: "Shirt Number",
      value: {
        text: options.player.shirt_number || "",
      },
    });
    this.data.push({
      name: "First Name",
      value: {
        text: options.player.first_name || "",
      },
    });
    this.data.push({
      name: "Last Name",
      value: {
        text: options.player.last_name || "",
      },
    });
    this.data.push({
      name: "First Name Uppercase",
      value: {
        text: options.player.first_name_upper || "",
      },
    });
    this.data.push({
      name: "Last Name Uppercase",
      value: {
        text: options.player.last_name_upper || "",
      },
    });
    this.data.push({
      name: "Full Name",
      value: {
        text:
          (options.player.first_name || "") +
          " " +
          (options.player.last_name || ""),
      },
    });

    let subtitle = "";
    if (options.goal.own_goal) {
      subtitle = "OWN GOAL";
    } else if (options.goal.penalty) {
      subtitle = "PENALTY";
    } else {
      subtitle = "GOAL";
    }
    this.data.push({
      name: "Subtitle",
      value: {
        text: subtitle,
      },
    });
    this.data.push({
      name: "Goal Time",
      value: {
        text: goalTime,
      },
    });
    this.data.push({
      name: "Team Name",
      value: {
        text: options.team?.name || "",
      },
    });

    this.data.push({
      name: "Team Badge",
      value: {
        image: options.team?.variables?.badge || "",
      },
    });

    this.sendPreview(this.scene, 50, this.data, "IN");
  }
  animate({ options }) {
    this.onAir = true;

    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }
}
