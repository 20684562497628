export class PlayerCam1 {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "PlayerCam1";
    this.scene = "FeNC_FF_1PlayerCam";
    this.data = [];
  }

  preview({ options }) {
    this.onAir = false;
    this.data = [];

    let left_team = options.switch
      ? options.game?.away_team
      : options.game?.home_team;
    let right_team = options.switch
      ? options.game?.home_team
      : options.game?.away_team;
    this.data.push({
      name: "LeftNameTEXT",
      value: {
        text: left_team?.name || "",
      },
    });
    this.data.push({
      name: "LeftTeamFLAG",
      value: {
        image: left_team?.code || "",
      },
    });
    this.data.push({
      name: "RightNameTEXT",
      value: {
        text: right_team?.name || "",
      },
    });

    this.data.push({
      name: "RightTeamFLAG",
      value: {
        image: right_team?.code || "",
      },
    });

    this.sendPreview(this.scene, 500, this.data, "IN");
  }
  animate({ options }) {
    this.onAir = true;

    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }
}
