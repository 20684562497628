import moment from "moment";

export class MatchID {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "MatchID";
    this.scene = "MatchID";
    this.data = [];
  }

  preview({ options }) {
    this.onAir = false;

    this.data = [];

    this.data.push({
      name: "Home Team Name",
      value: { text: options.game?.home_team?.name || "" },
    });

    this.data.push({
      name: "Home Team Badge",
      value: {
        image: options.game?.home_team?.variables?.badge,
      },
    });

    this.data.push({
      name: "Away Team Badge",
      value: {
        image: options.game?.away_team?.variables?.badge,
      },
    });

    this.data.push({
      name: "Home Team Video Badge",
      value: {
        video: options.game?.home_team?.variables?.video_badge,
      },
    });

    this.data.push({
      name: "Away Team Video Badge",
      value: {
        video: options.game?.away_team?.variables?.video_badge,
      },
    });

    this.data.push({
      name: "Away Team Name",
      value: { text: options.game?.away_team?.name || "" },
    });

    this.data.push({
      name: "Competition",
      value: { text: options.game?.competition?.name || "" },
    });
    this.data.push({
      name: "Stadium",
      value: { text: options.game?.stadium?.opta_name || "" },
    });
    this.data.push({
      name: "Kick-Off",
      value: {
        text:
          moment(options.game?.time, [moment.ISO_8601, "HH:mm"]).format(
            "HH:mm"
          ) || "",
      },
    });
    this.sendPreview(this.scene, 100, this.data, "IN");
  }
  animate({ options }) {
    this.onAir = true;
    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }
}
