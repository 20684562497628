export class PlayerStats {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "PlayerStats";
    this.scene = "FeNC_FF_PlayerStats";

    this.data = [];
  }

  preview({ options }) {
    this.onAir = false;
    this.data = [];

    this.data.push({
      name: "Header",
      value: {
        text: options?.player?.first_name?.toUpperCase() || "",
      },
    });

    this.data.push({
      name: "HeaderOutline",
      value: {
        text: options?.player?.first_name?.toUpperCase() || "",
      },
    });

    this.sendPreview(this.scene, 500, this.data, "IN");
  }
  animate({ options }) {
    this.onAir = true;

    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }
}
