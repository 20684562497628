import React, { useState, useEffect, useContext } from "react";

const UserContext = React.createContext([{}, () => {}]);

const UserProvider = (props) => {
  const [user, setUser] = useState();

  const [signinError, setSigninError] = useState();

  function parseJwt(token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  function setUserToken(token) {
    setUser(parseJwt(token));
  }

  function signOut() {
    localStorage.removeItem("user-id");
    localStorage.removeItem("token");
    window.location.reload();
  }
  return (
    <UserContext.Provider
      value={{
        user,
        setUserToken,
        signinError,
        signOut,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
