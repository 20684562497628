export class Lineup {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "Lineup";
    this.scene = "Lineup";
    this.data = [];
    this.page = 1;
  }

  preview({ options }) {
    this.onAir = false;
    this.data = [];
    this.options = options;
    if (this.page === 2) {
      this.scene = "Lineup Subs";
      this.data.push({
        name: "Title",
        value: {
          text: "SUBSTITUTES",
        },
      });
    } else {
      this.scene = "Lineup";
      this.data.push({
        name: "Title",
        value: {
          text: "STARTING",
        },
      });
    }

    new Array(11).fill(1).forEach((_, index) => {
      let row = index + 1;

      let player = options.squads?.find(
        (s) => s.order === row + (this.page === 2 ? 11 : 0)
      );

      this.data.push({
        name: "First Name " + row,
        value: {
          text: player?.squad?.player?.first_name || "",
        },
      });
      this.data.push({
        name: "Last Name " + row,
        value: {
          text: player?.squad?.player?.last_name || "",
        },
      });
      this.data.push({
        name: "First Name Uppercase " + row,
        value: {
          text: player?.squad?.player?.first_name_upper || "",
        },
      });
      this.data.push({
        name: "Last Name Uppercase " + row,
        value: {
          text: player?.squad?.player?.last_name_upper || "",
        },
      });
      this.data.push({
        name: "Captain " + row,
        value: {
          visible: player?.captain || false,
        },
      });
      this.data.push({
        name: "Position " + row,
        value: {
          text: player?.squad?.position || "",
        },
      });
      this.data.push({
        name: "Shirt Number " + row,
        value: {
          text: player?.squad?.shirt_number || "",
        },
      });
    });

    this.data.push({
      name: "Team Name",
      value: {
        text: options.team?.name,
      },
    });

    this.data.push({
      name: "Team Badge",
      value: {
        image: options.team?.variables?.badge,
      },
    });

    this.data.push({
      name: "Team Video Badge",
      value: {
        video: options.team?.variables?.video_badge,
      },
    });

    this.sendPreview(this.scene, 50, this.data, "IN");
  }
  animate({ options }) {
    this.onAir = true;
    this.sendAnimate(this.scene, this.data);
    if (this.page === 1) {
      this.page = 2;
      this.preview({ options: this.options });
    } else {
      this.page = 1;
    }
  }
  animateOff({ options }) {
    this.onAir = false;
    this.page = 1;
    this.sendAnimateOff(this.scene, []);
  }
}
