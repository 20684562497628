export class Group {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "Group";
    this.scene = "FeNC_FF_GroupStandings";
    this.data = [];
  }

  preview({ options }) {
    this.onAir = false;
    this.data = [];
    this.data.push({
      name: "GroupTXT",
      value: {
        text: options.group?.[0]?.group?.toUpperCase() || "",
      },
    });
    this.data.push({
      name: "HeaderOutline",
      value: {
        text: options.group?.[0]?.group?.toUpperCase() || "",
      },
    });
    options.group
      ?.sort((a, b) => {
        return a?.position - b?.position;
      })
      .map((player, index) => {
        let row = index + 1;
        debugger;
        this.data.push({
          name: "L" + row + "_Flag",
          value: {
            image: player?.team?.code || "",
          },
        });

        this.data.push({
          name: "L" + row + "_Pos",
          value: {
            text: player.position || "",
          },
        });
        this.data.push({
          name: "L" + row + "_Name",
          value: {
            text: player.team?.name?.toUpperCase() || "",
          },
        });
        this.data.push({
          name: "L" + row + "_WDL",
          value: {
            text:
              player.matchesWon +
              "-" +
              player.matchesDrawn +
              "-" +
              player.matchesLost,
          },
        });
        this.data.push({
          name: "L" + row + "_GP",
          value: {
            text: player.matchesPlayed || "0",
          },
        });
        this.data.push({
          name: "L" + row + "_GA",
          value: {
            text: player.goalsConceded || "0",
          },
        });
        this.data.push({
          name: "L" + row + "_GF",
          value: {
            text: player.goalsShot || "0",
          },
        });
        this.data.push({
          name: "L" + row + "_GD",
          value: {
            text: player.goalDifference || "0",
          },
        });
        this.data.push({
          name: "L" + row + "_PTS",
          value: {
            text: player.points || "0",
          },
        });
      });

    this.sendPreview(this.scene, 500, this.data, "IN");
  }
  animate({ options }) {
    this.onAir = true;

    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }
}
