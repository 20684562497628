export class FFHeartRate {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "FFHeartRate";
    this.scene = "FeNC_FF_Heartrate";
    this.data = [];
  }

  preview({ options }) {
    this.onAir = false;
    this.data = [];
    debugger;
    let left_team =
      options.teamSides === 0
        ? options.game?.home_team
        : options.game?.away_team;

    let right_team =
      options.teamSides === 1
        ? options.game?.home_team
        : options.game?.away_team;

    let left_score =
      options.teamSides === 0
        ? options.game?.home_scorers?.filter((s) => s.game === options.game_num)
            ?.length || 0
        : options.game?.away_scorers?.filter((s) => s.game === options.game_num)
            ?.length || 0;

    let right_score =
      options.teamSides === 1
        ? options.game?.home_scorers?.filter((s) => s.game === options.game_num)
            ?.length || 0
        : options.game?.away_scorers?.filter((s) => s.game === options.game_num)
            ?.length || 0;

    let left_lineup =
      options.teamSides === 0
        ? options.game.lineup?.home
        : options.game.lineup?.away;

    let right_lineup =
      options.teamSides === 1
        ? options.game.lineup?.home
        : options.game.lineup?.away;

    this.data.push({
      name: "L1_H_Score",
      value: {
        text: left_score,
      },
    });
    this.data.push({
      name: "L1_A_Score",
      value: {
        text: right_score,
      },
    });
    this.data.push({
      name: "L1_H_Name",
      value: {
        text: left_team?.name?.toUpperCase() || "",
      },
    });
    this.data.push({
      name: "L1_A_Name",
      value: {
        text: right_team?.name?.toUpperCase() || "",
      },
    });
    this.data.push({
      name: "L1_H_Flag",
      value: {
        image: left_team?.code || "",
      },
    });
    this.data.push({
      name: "L1_A_Flag",
      value: {
        image: right_team?.code || "",
      },
    });

    left_lineup?.forEach((player, index) => {
      let row = index + 1;

      this.data.push({
        name: "H_Player_" + row,
        value: {
          text: player?.squad?.player?.first_name?.toUpperCase() || "",
        },
      });
    });
    right_lineup?.forEach((player, index) => {
      let row = index + 1;

      this.data.push({
        name: "A_Player_" + row,
        value: {
          text: player?.squad?.player?.first_name?.toUpperCase() || "",
        },
      });
    });
    this.sendPreview(this.scene, 500, this.data, "IN", options.engine);
  }
  animate({ options }) {
    this.onAir = true;

    if (options) {
      this.preview({ options });
    }
    this.sendAnimate(this.scene, this.data, options?.engine);
  }
  animateOff({ options }) {
    this.onAir = false;

    this.sendAnimateOff(this.scene, [], options?.engine);
  }
  update({ options }) {
    this.data = [];
    let left_team =
      options.teamSides === 0
        ? options.game?.home_team
        : options.game?.away_team;

    let right_team =
      options.teamSides === 1
        ? options.game?.home_team
        : options.game?.away_team;

    let left_score =
      options.teamSides === 0
        ? options.game?.home_scorers?.filter((s) => s.game === options.game_num)
            ?.length || 0
        : options.game?.away_scorers?.filter((s) => s.game === options.game_num)
            ?.length || 0;

    let right_score =
      options.teamSides === 1
        ? options.game?.home_scorers?.filter((s) => s.game === options.game_num)
            ?.length || 0
        : options.game?.away_scorers?.filter((s) => s.game === options.game_num)
            ?.length || 0;

    let left_lineup =
      options.teamSides === 0
        ? options.game.lineup?.home
        : options.game.lineup?.away;

    let right_lineup =
      options.teamSides === 1
        ? options.game.lineup?.home
        : options.game.lineup?.away;

    this.data.push({
      name: "L1_H_Score",
      value: {
        text: left_score,
      },
    });
    this.data.push({
      name: "L1_A_Score",
      value: {
        text: right_score,
      },
    });
    this.data.push({
      name: "L1_H_Name",
      value: {
        text: left_team?.name?.toUpperCase() || "",
      },
    });
    this.data.push({
      name: "L1_A_Name",
      value: {
        text: right_team?.name?.toUpperCase() || "",
      },
    });

    left_lineup?.forEach((player, index) => {
      let row = index + 1;

      this.data.push({
        name: "H_HB_" + row,
        value: {
          text:
            options?.rates
              ?.find(
                (h) => h.hyperId === player?.squad?.player?.first_name_upper
              )
              ?.data?.bpm?.toString() || "",
        },
      });
    });
    right_lineup?.forEach((player, index) => {
      let row = index + 1;

      this.data.push({
        name: "A_HB_" + row,
        value: {
          text:
            options?.rates
              ?.find(
                (h) => h.hyperId === player?.squad?.player?.first_name_upper
              )
              ?.data?.bpm?.toString() || "",
        },
      });
    });

    this.sendUpdate(this.scene, this.data, options?.engine);
  }
}
